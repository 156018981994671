import styled from 'styled-components';
import colors from 'style/palette/palette';

export const LessonVideoBox = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
`;

export const VideoUrl = styled.input`
	width: 100%;
`;

export const AttachNewFileButton = styled.button`
	color: #000000;

  	max-width: 50%;

    border: none;
    border-radius: 4px;
    background-color: ${colors.lightSimpleGrey};

    cursor: pointer;
`;

export const AttachmentNewFile = styled.input.attrs({ 
    type: 'file',
  })`
    display: none;
`;

export const VideoUrlBox = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
`;

export const VideoButtonBox = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	gap: 1rem;

	> label {
		font-size: 12px;
	}
`;