import { callDELETE, callGET, callPOST, callPUT } from "network/network";

import { getBrand } from "configs/brandMap/brandMap.helper";

import { spreakerPlaylists } from 'configs/spreaker/spreaker-config';
import { AxiosProgressEvent } from "axios";

const _AUDIO_EXTENSIONS = ['mp3', 'm4a'];

export const getProductData = async (productId: string) => {
    
    try {
        
        const response = await callGET(`v1/productsforsell/${productId}`, true);
        if (!response || response === undefined) throw new Error("Unable getting product for sell data.");

        return response;
    } catch (error: any) {
        throw error;
    }
};

export const updateBlock = async (BlockId: number, blockDataToUpdate: any) => {
    
    try {
        
        const blockPayload = blockDataToUpdate;
        const response = await callPUT(`v1/webpages/blocks/elements`, blockPayload, true);
        if (!response || response === undefined) throw new Error("Unable update block.");

        return response;
    } catch (error: any) {
        throw error;
    }
};

export const updateElement = async (elementId: number, newElementText?: string | undefined, elementUrl?: string | undefined) => {
    
    try {
        interface ElementPyloadProps {
            id: number,
            data?: string,
            file?: {
                id?: string
            } | undefined
        };

        let elementPayload: ElementPyloadProps = {
            id: elementId
        };
        
        if (newElementText) elementPayload = {
            id: elementId,
            data: newElementText,
        };

        if (elementUrl) elementPayload = {
            id: elementId, 
            file: {
                id: elementUrl
            }
        };

        console.log("PAYLOAD:", elementPayload);
        
        const response = await callPUT(`v1/webpages/blocks/elements`, elementPayload, true);
        if (!response || response === undefined) throw new Error("Unable update element.");

        return response;
    } catch (error: any) {
        throw error;
    }
};

export const updateVideoOnSalesData = async (productId: number, newVideoUrl: string) => {
    const brand = getBrand();
    if (!brand) return;
    try {
        
        const videoParams = {
            brandCode: brand.code,
            url: newVideoUrl
        };
        const response = await callPUT(`v1/products/${productId}/salesdata/videosale`, {}, true, videoParams);
        if (!response || response === undefined) throw new Error("Unable update Video for Sales Page.");

        return response;
    } catch (error: any) {
        throw error;
    }
};

export const uploadVideoOnSales = async (productId: number, form?: FormData, url?: string, spreakerBrandCode?: string, onUploadProgress?: (progressEvent: AxiosProgressEvent) => void) => {
    const brand = getBrand();
    if (!brand) return;
    try {
        var params: any = {
            brandCode: brand.code
        }
        if (url) {
            params.url = url;
        }
        if (spreakerBrandCode && form) {
            const file = form.get('file');
            if (file instanceof File && _AUDIO_EXTENSIONS.includes(file.name.split('.').pop()!)) {
                params.spreakerShowId = spreakerPlaylists.find((playlist) => playlist.brandCode.includes(brand.code + spreakerBrandCode))?.spreakerShowId;
                params.spreakerShowKey = spreakerPlaylists.find((playlist) => playlist.brandCode.includes(brand.code + spreakerBrandCode))?.spreakerShowKey;
                if (!params.spreakerShowId || !params.spreakerShowKey) {
                    throw new Error('Spreaker config not found');
                }
            }
        }
        
        const response = await callPUT(`v1/products/${productId}/salesdata/videosale`, form ? form : {}, true, params, onUploadProgress, true);

        if (response === undefined || !response) {
            throw new Error();
        }

        return response;
    } catch (error: any) {
        throw error;
    }
};

export const deleteVideoOnSalesData = async (productId: number) => {
    const brand = getBrand();
    if (!brand) return;
    try {
        
        const videoParams = {
            brandCode: brand.code,
        };
        const response = await callDELETE(`v1/products/${productId}/salesdata/videosale`, {}, true, videoParams);

        return response;
    } catch (error: any) {
        throw error;
    }
};

export const updateVideoPosterOnSalesData = async (productId: number, newVideoImage: File) => {
    const brand = getBrand();
    if (!brand) return;
    try {
        
        const videoParams = {
            brandCode: brand.code,
        };
        const videoPayload = new FormData();
        videoPayload.append('file', newVideoImage);

        const response = await callPUT(`v1/products/${productId}/salesdata/videocover`, videoPayload, true, videoParams);
        if (!response || response === undefined) throw new Error("Unable update Video for Sales Page.");

        return response;
    } catch (error: any) {
        throw error;
    }
};

export const deleteVideoPosterOnSalesData = async (productId: number) => {
    const brand = getBrand();
    if (!brand) return;
    try {
        
        const videoParams = {
            brandCode: brand.code,
        };

        const response = await callDELETE(`v1/products/${productId}/salesdata/videocover`, {}, true, videoParams);

        return response;
    } catch (error: any) {
        throw error;
    }
};

export const updateVideoIntoElements = async (webpageId: number, elementId: number, newVideoUrl: string) => {
    const brand = getBrand();
    if (!brand) return;
    try {
        
        const videoParams = {
            brandCode: brand.code,
            url: newVideoUrl
        };

        const updatedVideo = await callPOST(`v1/webpages/${webpageId}/blocks/elements/addvideo`, {}, true, videoParams);
        if (!updatedVideo || updatedVideo === undefined) throw new Error("Unable update Video for Sales Page.");

        await updateElement(elementId, undefined, updatedVideo.id);
    } catch (error: any) {
        throw error;
    }
};

export const uploadImageIntoElements = async (webpageId: number, elementId: number, newImage: File) => {
    
    try {
        
        const imagePayload = new FormData();
        imagePayload.append('file', newImage);

        const updatedImage = await callPOST(`v1/webpages/${webpageId}/blocks/elements/uploadimage`, imagePayload, true);
        if (!updatedImage || updatedImage === undefined) throw new Error("Unable updating Image.");

        return updatedImage;
    } catch (error: any) {
        throw error;
    }
};

export const updateImageIntoElements = async (webpageId: number, elementId: number, newImage: File) => {
    
    try {
        
        const updatedImage = await uploadImageIntoElements(webpageId, elementId, newImage);
        await updateElement(elementId, undefined, updatedImage.id);
    } catch (error: any) {
        throw error;
    }
};

export const updateSalesData = async (updateData: any) => {
    const brand = getBrand();
    if (!brand) return;
    try {
      
        const salesDataParams = {
            brandCode: brand.code
        };
        const updatePayload = updateData;
        const response = await callPUT(`v1/products/salesdata/`, updatePayload, true, salesDataParams);
        // if (!response || response === undefined) throw new Error("Unable update Sales Page.");

        return response;
    } catch (error: any) {
        throw error;
    }
};

export const createWebPageForAProduct = async (productName: string, productId: number, isSalesDataPresent: boolean) => {
    const brand = getBrand();
    if (!brand) return;
    try {

        if (!isSalesDataPresent) {

            const salesDataPayload = {
                id: productId,
                salesData: {
                    productId
                }
            };
            const salesDataParams = {
                brandCode: brand.code
            };
            await callPUT('v1/products', salesDataPayload, true, salesDataParams);
        }
        
        const webPagePayload = {
            name: productName,
            type: 'SALES_PAGE',
            blocks: []
        };
        const newWebPage = await callPOST(`v1/webpages`, webPagePayload, true);
        if (!newWebPage || newWebPage === undefined) throw new Error("Unable create new Web Page.");

        const newWebPagePayload = {
            productId,
            salesPage: newWebPage
        };
        const updatedProduct = await updateSalesData(newWebPagePayload);
        return updatedProduct
    } catch (error: any) {
        throw error;
    }
};

export const createNewBlock = async (webpageId: number, newBlockPosition: number, newBlockLayout: 'SINGLE' | 'ROW' | 'GROUP') => {
    
    try {
        
        const blockPayload = {
            pos: newBlockPosition,
            layout: newBlockLayout,
            elements: []
        };
        const response = await callPOST(`v1/webpages/${webpageId}/blocks`, blockPayload, true);
        if (!response || response === undefined) throw new Error("Unable create new block for Web Page.");

        return response;
    } catch (error: any) {
        throw error;
    }
};

export const createNewElement = async (blockId: number, newElementType: any, elementPosition: number) => {
    
    try {
        
        const elementPayload = {
            type: newElementType,
            data: null,
            file: null,
            pos: elementPosition
        };
        const response = await callPOST(`v1/webpages/blocks/${blockId}/elements`, elementPayload, true);
        if (!response || response === undefined) throw new Error("Unable create new element for Web Page.");

        return response;
    } catch (error: any) {
        throw error;
    }
};

export const deleteBlock = async (blockId: number) => {
    
    try {
        
        await callDELETE(`v1/webpages/blocks/${blockId}`, {}, true);
    } catch (error: any) {
        throw error;
    }
};

export const deleteElement = async (elementId: number) => {
    
    try {
        
        await callDELETE(`v1/webpages/blocks/elements/${elementId}`, {}, true);
    } catch (error: any) {
        throw error;
    }
};

export const updateThematicBroadcastDescription = async (productId: number, description: string) => {
    const brand = getBrand();
    if (!brand) return;
    try {
        
        const URL = 'v1/products/{id}/thematicbroadcast/description';
        const response = await callPUT(URL.replaceAll("{id}", productId.toString()), {}, true, {description: description, brandCode: brand.code});

        return response;
    } catch (error: any) {
        throw error;
    }
};