import { FC, useState } from 'react';

// utils
import { createNewBlock, createNewElement, updateElement, updateSalesData, updateThematicBroadcastDescription } from 'pages/PublicProductPages/services/PublicProductPage.services';

//types
import { TextUpdateElementProps } from './TextUpdateElement.component.d';

//components
import { CKEditor } from 'ckeditor4-react';

//style
import {
    CardContent,
    ButtonRow,
    SaveAndCloseButton,
    SaveButton,
    CloseAndCancel,
    DeleteElementButton
 } from './style/TextUpdateElement.style';
import DeleteElementModal from 'components/DeleteElementModal/DeleteElementModal.component';


const TextUpdateElement: FC<TextUpdateElementProps> = ({
    productId,
    webpageId,
    blockId,
    elementId,
    editableContent,
    isRequired,
    isNewElement,
    typeOfLayout,
    newBlockLayout,
    newBlockPosition,
    newElementPosition,
    numberOfElements,
    typeOfTextToUpload,
    onCancelClickHandler,
    updateData,
    update
}) => {

    const [onChangeTextAreaValue, setOnChangeTextAreaValue] = useState<any>(editableContent);
    const [isRemoveElementModalVisible, setIsRemoveElementModalVisible] = useState<boolean>(false);

    const onDeleteButtonHandler = () => {
        
        if (isRequired) return;
        setIsRemoveElementModalVisible(true);
    };

    const onChangeTextAreaHandler = (e: any) => {
        setOnChangeTextAreaValue(e)
    };
    
    const onCancelButtonClickHandler = () => onCancelClickHandler();

    const onSaveAndCloseHandler = async (typeOfTextToUpload?: string) => {
        
        // console.log("NEW EL:", isNewElement);
        // console.log("NEW B L:", newBlockLayout);
        // console.log("NEW EL POS:", newElementPosition);
        // console.log("B ID:", blockId);
        // console.log("typeOfTextToUpload:", typeOfTextToUpload);
        
        if (isNewElement && (newBlockLayout !== undefined) && ((newElementPosition === undefined) || (newElementPosition === 0))) {
            await creteNewTextElement();
            if (updateData) await updateData();
            onCancelClickHandler();    
            return;
        }

        if (isNewElement && (newElementPosition !== undefined && newElementPosition > 0)) {
            await creteNewTextElementInsideABlock();
            if (updateData) await updateData();
            onCancelClickHandler();    
            return;
        }

        await updateText(typeOfTextToUpload);
        if (updateData) await updateData();
        onCancelClickHandler();
    };

    const onSaveHandler = async (typeOfTextToUpload?: string) => {
        
        await updateText(typeOfTextToUpload);
        if (updateData) await updateData();
    };

    const updateText = async (typeOfTextToUpload?: string) => {

        // to do snackbar and spinner
        try {

            let updateData = {
                productId,
                headLine: undefined,
                importantPhrase: undefined,
                delivery: undefined,
                thematicBroadcastDescription: undefined,
            };
            // if (typeOfTextToUpload === 'delivery') updateData.salesData.delivery = onChangeTextAreaValue;
            if (typeOfTextToUpload === undefined) updateData.headLine = onChangeTextAreaValue;
            if (typeOfTextToUpload === 'importantPhrase') updateData.importantPhrase = onChangeTextAreaValue;
            if (typeOfTextToUpload === 'delivery') updateData.delivery = onChangeTextAreaValue;
            if ((productId) && (typeOfTextToUpload !== 'thematicbroadcastdescription')) await updateSalesData(updateData);
            if ((productId) && (typeOfTextToUpload === 'thematicbroadcastdescription')) await updateThematicBroadcastDescription(productId, onChangeTextAreaValue);
            if ((elementId && update) && (typeOfTextToUpload !== 'thematicbroadcastdescription')) await update(elementId, onChangeTextAreaValue, undefined);
        } catch (error: any) {
            console.warn("ERROR:", error);
        }
    };

    const creteNewTextElement = async () => {

        // to do snackbar and spinner
        try {

            if (webpageId === undefined) return;
            if (newBlockPosition === undefined) return;
            if (newBlockLayout === undefined) return;
            const newBlock = await createNewBlock(webpageId, newBlockPosition, newBlockLayout);

            if (newBlock.id === undefined) return;
            const newElement = await createNewElement(newBlock.id, 'TEXT', 0);

            if (newElement.id === undefined) return;
            await updateElement(newElement.id, onChangeTextAreaValue, undefined);

            // if (productId) await updateSalesData(productId, updateData);
            // if (elementId && update) await update(elementId, onChangeTextAreaValue, undefined);
            
        } catch (error: any) {
            console.warn("ERROR:", error);
        }
    };

    const creteNewTextElementInsideABlock = async () => {

        // to do snackbar and spinner
        try {

            // if (webpageId === undefined) return;
            if (blockId === undefined) return;
            if (newElementPosition === undefined) return;
            // const newBlock = await createNewBlock(webpageId, newBlockPosition, newBlockLayout);

            // if (newBlock.id === undefined) return;
            const newElement = await createNewElement(blockId, 'TEXT', newElementPosition);

            if (newElement.id === undefined) return;
            await updateElement(newElement.id, onChangeTextAreaValue, undefined);

            // if (productId) await updateSalesData(productId, updateData);
            // if (elementId && update) await update(elementId, onChangeTextAreaValue, undefined);
            
        } catch (error: any) {
            console.warn("ERROR:", error);
        }
    };

    return (
        <>
            <CardContent>
            {blockId && <>"Stai modificando l'elemento nr°" {blockId}</>}

                <CKEditor 
                    initData={onChangeTextAreaValue}
                    onChange={e => onChangeTextAreaHandler(e.editor.getData())}
                    config={{
                        language: 'it',
                        extraAllowedContent: 'video[*]{*}(*);source[*]{*}(*);',
                        allowedContent: true,
                        height: '60vh'
                    }}
                />

                <ButtonRow>
                    {!isRequired && 
                    !isNewElement && 
                    (newBlockLayout === undefined) && (
                        <DeleteElementButton
                            onClick={onDeleteButtonHandler}
                        >
                            Elimina questo elemento
                        </DeleteElementButton>
                        )
                    }

                    <CloseAndCancel
                        onClick={onCancelButtonClickHandler}
                    >
                        Annulla e chiudi
                    </CloseAndCancel>

                    {!isNewElement && 
                    (newBlockLayout === undefined) && 
                    <SaveButton
                        onClick={() => onSaveHandler(typeOfTextToUpload)}
                    >
                        Salva
                    </SaveButton>}

                    <SaveAndCloseButton
                        onClick={() => onSaveAndCloseHandler(typeOfTextToUpload)}
                    >
                        Salva e chiudi
                    </SaveAndCloseButton>
                </ButtonRow>
            </CardContent>

            {isRemoveElementModalVisible && (
                <DeleteElementModal 
                    onCloseButtonModal={onCancelButtonClickHandler}
                    blockId={blockId}
                    elementId={elementId}
                    updateData={updateData}
                    typeOfLayout={typeOfLayout}
                    numberOfElements={numberOfElements}
                />)
            }
        </>
    );
};

export default TextUpdateElement;
