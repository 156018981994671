import styled from 'styled-components';
import colors from 'style/palette/palette'; 

export const MainBox = styled.div`
	display: flex;
	flex-direction: row;
	gap: 10px;
	width: 100%;
	align-items: center;
    justify-content: center;
    padding: 0 10px 10px 0;
`;

export const BrandCheckbox = styled.input.attrs({ type: 'checkbox' })`
	width: 20px;
	height: 20px;   
`;

export const BrandItem = styled.div`
	display: flex;
	flex-direction: row;
	gap: 2px;
	width: 100%;
	align-items: center;
    color: white;
`;

export const ButtonSave = styled.button`
	cursor: pointer;
    white-space: nowrap;
`;