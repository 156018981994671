import { FC, useState } from 'react';

// redux
import { enableAppMain } from 'redux/slices/slice-app-main';

// hooks
import useAppDispatch from 'hooks/useAppDispatch';

// type
import { SectionEditorModalProps } from './SectionEditorModal.component.d';

// components
import BaseModal from 'components/ui/BaseModal';
import { BaseCard } from 'style/wrappers';
import VideoUpdateElement from '../UpdateElements/VideoUpdateElement/VideoUpdateElement.component';
import ImageUpdateElement from '../UpdateElements/ImageUpdateElement/ImageUpdateElement.component';
import RowUpdateElement from '../UpdateElements/RowUpdateElement/RowUpdateElement.component';
import GroupUpdateElement from '../UpdateElements/GroupUpdateElement/GroupUpdateElement.component';
import TextUpdateElement from '../UpdateElements/TextUpdateElement/TextUpdateElement.component';
import ButtonUpdateElement from '../UpdateElements/ButtonUpdateElement/ButtonUpdateElement.component';
import BulletListUpdateElement from '../UpdateElements/BulletListUpdateElement/BulletListUpdateElement.component';

// style
import { } from './style/SectionEditorModal.style';


const SectionEditorModal: FC<SectionEditorModalProps> = ({
    productId,
    webpageId,
    blockId,
    elementId,
    editableContent,
    typeOfLayout,
    typeOfElement,
    elementsToDisplay,
    isRequired,
    newElementPos,
    numberOfElements,
    typeOfTextToUpload,
    onCloseButtonModal,
    update,
    updateData,
    videoUrl,
    pictureUrl,
    spreakerBrandCode
}) => {

    const [isCloseButtonClicked, setIsCloseButtonClicked] = useState<boolean>(false);
    const [onChangeTextAreaValue, setOnChangeTextAreaValue] = useState<any>(editableContent);
    const dispatch = useAppDispatch();

	const closeButtonHandler = () => {
		if (onCloseButtonModal) onCloseButtonModal();
        setIsCloseButtonClicked(true);
	};

    const onSaveAndCloseClickHandler = async () => {
        
        if (update && elementId) await update(elementId, undefined, onChangeTextAreaValue);     
        if (onCloseButtonModal) onCloseButtonModal();
        setIsCloseButtonClicked(true);
        dispatch( enableAppMain() );
    };

    const onSaveClickHandler = async () => {
        
        if (update && elementId) await update(elementId, undefined, onChangeTextAreaValue);     
    };

    const onCancelClickHandler = () => {
        
        if (onCloseButtonModal) onCloseButtonModal();
        setIsCloseButtonClicked(true);
        dispatch( enableAppMain() );        
    };

    return (
		<BaseModal
			onClose={closeButtonHandler}
			isCloseButtonClicked={isCloseButtonClicked}
		>
			<BaseCard
				flavor='outlined'
				
			>
                {(typeOfLayout === 'ROW') && (
                    <RowUpdateElement 
                        elementsToDisplay={elementsToDisplay}
                        onCancelClickHandler={onCancelClickHandler}
                        newElementPos={newElementPos}
                        blockId={blockId}
                        updateData={updateData}
                        webpageId={webpageId}
                    />
                )}

				{(typeOfLayout === 'GROUP') && (
                    <GroupUpdateElement
                        elementsToDisplay={elementsToDisplay}
                        onCancelClickHandler={onCancelClickHandler}
                        newElementPos={newElementPos}
                        blockId={blockId}
                        updateData={updateData}
                        webpageId={webpageId}
                    />
                )}

                {(typeOfElement === 'TEXT') && (
                    <TextUpdateElement 
                        onCancelClickHandler={onCancelClickHandler}
                        update={update}
                        productId={productId}
                        blockId={blockId}
                        elementId={elementId}
                        editableContent={editableContent}
                        isRequired={isRequired}
                        updateData={updateData}
                        typeOfLayout={typeOfLayout}
                        numberOfElements={numberOfElements}
                        typeOfTextToUpload={typeOfTextToUpload}
                    />
                )}

				{(typeOfElement === 'VIDEO') && (
                    <VideoUpdateElement 
                        onClose={onCancelClickHandler}
                        isRequired={isRequired}
                        productId={productId}
                        webpageId={webpageId}
                        blockId={blockId}
                        elementId={elementId}
                        update={update}
                        updateData={updateData}
                        numberOfElements={numberOfElements}
                        videoUrl={videoUrl}
                        pictureUrl={pictureUrl}
                        spreakerBrandCode={spreakerBrandCode}
                    />
                )}

				{(typeOfElement === 'IMAGE') && (
                    <ImageUpdateElement 
                        onClose={onCancelClickHandler}
                        update={update}
                        blockId={blockId}
                        elementId={elementId}
                        webpageId={webpageId}
                        updateData={updateData}
                        numberOfElements={numberOfElements}
                    />
                )}

				{(typeOfElement === 'BUTTON') && (
                    <ButtonUpdateElement 
                        onClose={onCancelClickHandler}
                        isRequired={isRequired}
                        productId={productId}
                        updateData={updateData}
                    />
                )}

				{(typeOfElement === 'BULLETLIST') && (
                    <BulletListUpdateElement 
                        onClose={onCancelClickHandler}
                        isRequired={isRequired}
                        elementToDisplay={elementsToDisplay}
                        productId={productId}
                        updateData={updateData}
                    />
                )}
			</BaseCard>
		</BaseModal>
    )
};

export default SectionEditorModal;
