import { FC, useEffect, useState } from 'react';

//redux
import { addSnackbar } from 'redux/actions/actions-snackbar';
import { addProductToState } from 'redux/actions/actions-global';

//hooks
import useAppDispatch from 'hooks/useAppDispatch';

//types
import { AddProductModalProps } from './AddProductModal.component.d';
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';

//components
import BaseModal from 'components/ui/BaseModal';
import { BaseCard } from 'style/wrappers';

//utils
import { addProduct } from '../services/ProductsPage.services';

//style
import { CardContent, CloseButton, IconBox, ProductName, ProductType, TextContent, TextDisclaimer, Title } from './style/AddProductModal.component.style';
import { Product } from 'types/Product';
import { ProductCourse } from 'types/ProductCourse';
import { ProductService } from 'types/ProductService';
import { ProductLessonPack } from 'types/ProductLessonPack';
import { ProductThematicBroadcast } from 'types/ProductThematicBroadcast';
import { Pool } from 'types/Pool';
import { ProductPool } from 'types/ProductPool';
import { useNavigate } from 'react-router-dom';
import { enableAppMain } from 'redux/slices/slice-app-main';
import useHasRole from 'hooks/useHasRole';
import { getBrand } from 'configs/brandMap/brandMap.helper';

const AddProductModal: FC<AddProductModalProps> = ({ defaultType, defaultCategory, onCloseButtonModal }) => {
	const [isCloseButtonClicked, setIsCloseButtonClicked] = useState<boolean>(false);
	const [productType, setProductType] = useState(defaultType);
	const [productCategory, setProductCategory] = useState(defaultCategory);
	const [productName, setProductName] = useState('');
	const [isAddEnabled, setIsAddEnabled] = useState(false);

	const isAdmin = useHasRole('ADMIN');
	const isSuperAdmin = useHasRole('SUPERADMIN');
	const isMiniAdmin = useHasRole('MINIADMIN');
	const isAuthor = useHasRole('AUTORE');
	const isAnimator = useHasRole('ANIMATORE');
	var isDiretta = useHasRole('DIRETTA');
	const isRegistaLDL = useHasRole('REGISTA-LDL');
	const isRegistaMB = useHasRole('REGISTA-MB')
	const isRegistaTCR = useHasRole('REGISTA-TCR');
	const isRegistaOMK = useHasRole('REGISTA-OMK');
	const isEditorLDL = useHasRole('EDITOR-LDL');
	const isEditorMB = useHasRole('EDITOR-MB');
	const isEditorTCR = useHasRole('EDITOR-TCR');
	const isEditorVDR = useHasRole('EDITOR-VDR');
	const isEditorOMK = useHasRole('EDITOR-OMK');
	isDiretta = isDiretta || isRegistaLDL || isRegistaMB || isRegistaTCR || isEditorLDL || isEditorMB || isEditorTCR || isEditorOMK;
	
	const canCreateCourseServiceLessonTab = isAdmin || isSuperAdmin || isMiniAdmin || isAuthor;
	const canCreateThematicCallBroadcastTab = isAdmin || isSuperAdmin || isMiniAdmin || isAuthor || isDiretta;
	const canCreateInitiativeTab = isAdmin || isSuperAdmin || isEditorLDL || isEditorMB || isEditorTCR || isEditorVDR || isEditorOMK;

	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const brand = getBrand();

	const removeBrackets = (str: string) => {
		return str.replace(/\[\w+\]/g, '');
	};

	const extractBrackets = (str: string) => {
		return str.match(/\[(.*?)\]/)?.[1] || '';
	};
	
	const closeButtonHandler = () => {
		if (onCloseButtonModal) onCloseButtonModal();
		dispatch(enableAppMain());
	};

	const onProductTypeChangeHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
		setProductType(removeBrackets(event.currentTarget.value));
		setProductCategory(extractBrackets(event.currentTarget.value));
	};

	const onProductNameChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		setProductName(event.currentTarget.value);
	};

	const addProductHandler = () => {
		if (window.confirm(`Aggiungere il prodotto di tipo ${productCategory !== '' ? productCategory : productType} e nome "${productName}" ?`)) {
			callAddProduct();
		}
	};

	const callAddProduct = async () => {
		if (!brand) return;
		const snackbarData: SnackbarOptions = {};
		const newProduct: Partial<Product> = { name: productName, visible: false, duplicationIncentiveActive: false };
		if (productType === 'COURSE') newProduct.productCourse = { price: 0 } as ProductCourse;
		if (productType === 'SERVICE') newProduct.productService = {} as ProductService;
		if (productType === 'LESSON_PACK') newProduct.productLessonPack = { price: 0, type: { id: 1 }, lessonsNumber: 1 } as ProductLessonPack;
		// if (productType === 'THEMATIC_BROADCAST' && productCategory !== 'ESPERIENZA') newProduct.productThematicCall = { price: 0, discountProtagonistPrice: 0, discountSpectatorPrice: 0, onShop: true, protagonistPrice: 0, spectatorPrice: 0, vip: false} as ProductThematicBroadcast;
		// if (productType === 'THEMATIC_BROADCAST' && productCategory === 'ESPERIENZA') newProduct.productThematicCall = { price: 0, onShop: true, vip: false, type: 'ESPERIENZA'} as ProductThematicBroadcast;
		if (productType === 'THEMATIC_BROADCAST') newProduct.productThematicCall = { price: 0, protagonistPrice: 0, spectatorPrice: 0, onShop: true, vip: ((productCategory === 'ESPERIENZA') && (brand.code === 'VDR')) ? true : false} as ProductThematicBroadcast;
		if (productType === 'THEMATIC_BROADCAST') newProduct.visible = true;
		if (productType === 'THEMATIC_BROADCAST' && newProduct.productThematicCall) {
			if (productCategory === 'ESPERIENZA') {
				newProduct.productThematicCall.type = 'ESPERIENZA';
				newProduct.productThematicCall.maxSpectator = 0;
			} else {
				newProduct.productThematicCall.type = 'LABORATORIO';
			}
		}

		try {
			const response = await addProduct(newProduct);
			console.log('Product added to API:', response);
			
			await Promise.all([
				dispatch(addProductToState(response)),
				new Promise(resolve => setTimeout(resolve, 100)) // Piccolo delay per sicurezza
			]);
			
			console.log('Product added to Redux state');
			
			snackbarData.type = 'success';
			snackbarData.message = 'Prodotto aggiunto correttamente';
			
			await dispatch(addSnackbar(snackbarData));
			if (onCloseButtonModal) onCloseButtonModal();
			console.log('created new product, now going to reservedArea/products/' + response.id);

			navigate('/reservedArea/products/' + response.id);
		} catch (error: any) {
			console.error('Error adding product:', error);
			snackbarData.type = 'error';
			snackbarData.message = 'Non è stato possibile aggiungere il prodotto.';
			dispatch(addSnackbar(snackbarData));
		}
	};

	useEffect(() => {
		if (productName?.length > 0 && productType?.length > 0) setIsAddEnabled(true);
	}, [productName, productType]);

	return (
		<BaseModal
			isBackdropClickActive={true}
			onClose={closeButtonHandler}
			isCloseButtonClicked={isCloseButtonClicked}
		>
			<BaseCard
				flavor='outlined'
				maxWidth='700px'
			>
				<CardContent>
					<ProductType>
						<label>Tipo prodotto:</label>
						<select
							id='product-type'							
							value={productType + (productCategory ? '[' + productCategory + ']' : '')}
							onChange={onProductTypeChangeHandler}
						>
							{ canCreateCourseServiceLessonTab && <>
								<option value='COURSE'>CORSO</option>
								<option value='SERVICE'>ABBONAMENTO</option>
								<option value='LESSON_PACK'>PACCHETTO LEZIONI</option>
							</>}
							{ canCreateThematicCallBroadcastTab && 
								<option value='THEMATIC_BROADCAST'>AULA</option>
							}
							{ canCreateInitiativeTab && 
								<option value='THEMATIC_BROADCAST[ESPERIENZA]'>ESPERIENZA</option>
							}
						</select>
					</ProductType>
					<ProductName>
						<label>Nome prodotto:</label>
						<input
							type='text'
							size={50}
							value={productName}
							onChange={onProductNameChangeHandler}
						></input>
					</ProductName>
					<button
						disabled={!isAddEnabled}
						onClick={addProductHandler}
					>
						Aggiungi prodotto
					</button>
				</CardContent>
			</BaseCard>
		</BaseModal>
	);
};

export default AddProductModal;
