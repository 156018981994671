import React, { FC, useEffect, useState } from 'react';

//redux
import { addSnackbar } from 'redux/actions/actions-snackbar';

//hooks
import { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import useAppDispatch from 'hooks/useAppDispatch';
import useHasRole from 'hooks/useHasRole';

//types
import { ContentNodeProps, DragItem } from './ContentNode.component.d';
import type { Identifier, XYCoord } from 'dnd-core';
import { CourseNode } from 'types/Course';
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';


//utils
import { getNodeDepth, getNodeLevel, getNodePath } from './ContentNode.helper';

//components
import VideoPreviewModal from 'components/VideoPreviewModal';
import NodeTitle from './NodeTitle';

//assets
import { ReactComponent as DragHandleIcon } from './assets/dragHandleIcon.svg';
import { ReactComponent as ExpandLessIcon } from './assets/expandLessIcon.svg';
import { ReactComponent as ExpandMoreIcon } from './assets/expandMoreIcon.svg';
import { ReactComponent as AttachmentIcon } from './assets/attachmentIcon.svg';
import { ReactComponent as DescriptionIcon } from './assets/descriptionIcon.svg';
import { ReactComponent as ModifyIcon } from './assets/modifyIcon.svg';
import { ReactComponent as DeleteIcon } from './assets/deleteIcon.svg';
import { ReactComponent as AddBoxIcon } from '../assets/addBoxIcon.svg';
import { ReactComponent as NewLessonIcon } from './assets/newLessonIcon.svg';
import { ReactComponent as VideoIcon } from './assets/onDemandVideoIcon.svg';
import { ReactComponent as LinkIcon } from './assets/linkIcon.svg';

//style
import {
	ContentNodeBox,
	ExpandCollapse,
	Handle,
	Video,
	Description,
	Attachment,
	ModifyLesson,
	LeftSection,
	RightSection,
	LessonId,
	NodeId,
	DeleteNode,
	AddChildNode,
	LessonSection,
	AddLesson,
} from './style/ContentNode.component.style';
import ModifyLessonModal from './ModifyLessonModal';

export const ItemTypes = {
	CONTENT_NODE: 'contentNode',
};

const ContentNode: FC<ContentNodeProps> = ({ node, nodes, product, moveNode, updateLocalNode, reArrangePositions, addNode, deleteNode, addLessonToNode }) => {
	const [path, setPath] = useState('');
	const [level, setLevel] = useState(0);
	const [isExpanded, setIsExpandend] = useState(false);
	const [hasLesson, setHasLesson] = useState(false);
	const [children, setChildren] = useState<CourseNode[]>([]);
	const [depth, setDepth] = useState(0);
	const [isOnHover, setIsOnHover] = useState(false);
	const [isVideoPreviewModalVisible, setIsVideoPreviewModalVisible] = useState<boolean>(false);
	const [isModifyLessonModalVisible, setIsModifyLessonModalVisible] = useState<boolean>(false);
	
	const dispatch = useAppDispatch();

	const dragRef = useRef<HTMLDivElement>(null);
	const previewRef = useRef<HTMLDivElement>(null);

	const isAdmin = useHasRole('ADMIN');
	const isSuperAdmin = useHasRole('SUPERADMIN');
	const userHasAdminRole = isAdmin || isSuperAdmin;

	const domain = window.location.hostname;
    const topLevelDomain = domain.split('.').slice(-2).join('.');
    const brandBaseLink = `https://${topLevelDomain}`;

	const onMouseOverHandler = (event: React.MouseEvent<HTMLInputElement>) => {
		setIsOnHover(true);
	};

	const onMouseLeaveHandler = (event: React.MouseEvent<HTMLInputElement>) => {
		setIsOnHover(false);
	};

	const linkClickHandler = async (node: CourseNode) => {
		const productType = product.type.toLowerCase();
		const url = `${brandBaseLink}/app/reservedArea/product-page/${productType}/${product.id}?nodeID=${node.id}`;
		navigator.clipboard.writeText(url).then(() => {
			const snackbarData: SnackbarOptions = {};
			snackbarData.type = 'success';
			snackbarData.message = 'Link copiato!';
			dispatch(addSnackbar(snackbarData));
		});
	}

	const addChildNodeHandler = async (event: React.MouseEvent<HTMLButtonElement>) => {
		await addNode({
			courseId: node.courseId,
			title: 'Aggiungi Titolo',
			parentId: node.id,
			pos: nodes.filter((n) => n.parentId === node.id).length,
		});
		setIsExpandend(true);
	};

	const deleteNodeClickHandler = async (event: React.MouseEvent<HTMLButtonElement>) => {
		if (window.confirm('Cancellare il nodo ' + node.id + ' | ' + node.title + ', e tutti i suoi figli?')) await deleteNode(node.id);
	};

	const videoPreviewClickHandler = (event: React.MouseEvent<HTMLDivElement>) => {
		setIsVideoPreviewModalVisible(true);
	};

	const createLessonClickHandler = async (event: React.MouseEvent<HTMLDivElement>) => {
		await addLessonToNode(node);
	};

	const modifyLessonClickHandler = (event: React.MouseEvent<HTMLDivElement>) => {
		setIsModifyLessonModalVisible(true);
	};

	const closeVideoPreviewModal = () => {
		setIsVideoPreviewModalVisible(false);
	};

	const closeModifyLessonModal = () => {
		setIsModifyLessonModalVisible(false);
	};

	const expandClickHandler = (event: React.MouseEvent<HTMLDivElement>) => {
		setIsExpandend((prevState) => !prevState);
	};

	const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
		accept: ItemTypes.CONTENT_NODE,
		collect(monitor) {
			return {
				handlerId: monitor.getHandlerId(),
				canDrop: monitor.canDrop(),
			};
		},
		canDrop(item: DragItem, monitor) {
			const dragPath = item.fromPath;
			const hoverPath = path;
			const hoverLevel = level;

			if (dragPath === hoverPath) return false;

			if (hoverLevel + item.depth > 2) return false;

			return true;
		},
		hover(item: DragItem, monitor) {
			if (!previewRef.current) {
				return;
			}

			const dragId = item.nodeId;
			const hoverId = node.id;

			// const dragParentId = item.parentId;
			// const hoverParentId = node.parentId;

			const dragPath = item.fromPath;
			const hoverPath = path;

			// const dragPos = item.pos;
			// const hoverPos = node.pos;

			// const dragLevel = item.level;
			const hoverLevel = level;

			// const dragDepth = item.depth;
			// const hoverDepth = depth;

			//Don't replace items with themselves
			if (dragPath === hoverPath) return;

			if (hoverLevel + item.depth > 2) return;

			// const expandTimeout = setTimeout(() => {
			// 	setIsExpandend(true);
			// }, 500);

			// Determine rectangle on screen
			const hoverBoundingRect = previewRef.current?.getBoundingClientRect();

			// Get vertical middle
			const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

			// Determine mouse position
			const clientOffset = monitor.getClientOffset();

			// Get pixels to the top
			const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

			// Only perform the move when the mouse has crossed half of the items height
			// When dragging downwards, only move when the cursor is below 50%
			// When dragging upwards, only move when the cursor is above 50%

			// Dragging downwards
			// console.log('dragPath', dragPath);
			// console.log('hoverPath', hoverPath);

			// console.log('downwards check');

			// console.log('dragPath < hoverPath', dragPath < hoverPath);
			// console.log('hoverClientY < hoverMiddleY', hoverClientY < hoverMiddleY);

			if (dragPath < hoverPath && hoverClientY < hoverMiddleY) {
				return;
			}

			// console.log('upwards check');
			// console.log('dragPath > hoverPath', dragPath > hoverPath);
			// console.log('hoverClientY > hoverMiddleY', hoverClientY > hoverMiddleY);
			// // Dragging upwards
			if (dragPath > hoverPath && hoverClientY > hoverMiddleY) {
				return;
			}

			// Time to actually perform the action

			if (dragId === hoverId) return;
			// console.log('moving node');
			// console.log('dragId, hoverId, deltaHoverPos', dragId, hoverId, deltaHoverPos);
			moveNode(dragId, hoverId);

			// Note: we're mutating the monitor item here!
			// Generally it's better to avoid mutations,
			// but it's good here for the sake of performance
			// to avoid expensive index searches.
			// item.index = hoverIndex;
		},
		// drop: () => {
		// 	reArrangePositions();
		// },
	});

	const [{ isDragging }, drag, preview] = useDrag({
		type: ItemTypes.CONTENT_NODE,
		item: { nodeId: node.id, fromPath: path, parentId: node.parentId, pos: node.pos, depth, level } as DragItem,
		collect: (monitor) => ({
			isDragging: monitor.isDragging(),
		}),
	});

	drag(dragRef);
	drop(preview(previewRef));

	useEffect(() => {
		setPath(getNodePath(node, nodes));
		setLevel(getNodeLevel(node, nodes));
		setDepth(getNodeDepth(node, nodes));
	}, [node, nodes]);

	useEffect(() => {
		setChildren(nodes.filter((n) => n.parentId === node.id));
		setHasLesson(node.lesson !== undefined);
	}, [node, nodes]);

	useEffect(() => {
		setIsExpandend(false);
	}, [isDragging]);

	return (
		<>
			<ContentNodeBox
				ref={previewRef}
				data-handler-id={handlerId}
				isDragging={isDragging}
				level={level}
				hasLesson={hasLesson}
				onMouseOver={onMouseOverHandler}
				onMouseLeave={onMouseLeaveHandler}
				title={`video: ${node.lesson?.video ? node.lesson?.video?.url : ''} \ndescription: ${
					node.lesson?.description ? node.lesson.description : ''
				} \nattachments: ${node.lesson?.attachments?.length > 0 ? node.lesson?.attachments?.map((a) => a.fileName + ' ') : ''}`}
			>
				<LeftSection>
					{userHasAdminRole && (
						<Handle
							ref={dragRef}
							level={level}
							node={node}
						>
							<DragHandleIcon />
						</Handle>
					)}
					<ExpandCollapse onClick={expandClickHandler}>
						{children.length > 0 && isExpanded && <ExpandLessIcon />}
						{children.length > 0 && !isExpanded && <ExpandMoreIcon />}
					</ExpandCollapse>
					<LinkIcon onClick = { () => linkClickHandler(node)}/>
					<NodeTitle
						nodeId={node.id}
						nodeTitle={node.title}
						updateLocalNode={updateLocalNode}
					/>
				</LeftSection>
				{/* pos: {node.pos} */}
				<LessonSection>
					{node.lesson?.video && (
						<Video
							onClick={videoPreviewClickHandler}
							title={node.lesson.video.url}
						>
							<VideoIcon />
						</Video>
					)}
					{node.lesson?.description && (
						<Description>
							<DescriptionIcon />
						</Description>
					)}
					{node.lesson?.attachments?.length > 0 && (
						<Attachment>
							<AttachmentIcon />
						</Attachment>
					)}
				</LessonSection>
				<RightSection>
					<NodeId>ID nodo: {node.id}</NodeId>
					{node.lesson && <LessonId>{'ID Lezione: ' + node.lesson?.id}</LessonId>}
					{userHasAdminRole && (
						<>
							{isOnHover && level>0 && !node.lesson && children.length === 0 && (
							<AddLesson
								title='Crea lezione'
								onClick={createLessonClickHandler}
							>
								<NewLessonIcon />
							</AddLesson>
							)}
							{isOnHover && node.lesson && (
								<ModifyLesson
									title='Modifica lezione'
									onClick={modifyLessonClickHandler}
								>
									<ModifyIcon />
								</ModifyLesson>
							)}
							{isOnHover && (
								<DeleteNode
									title='Cancella nodo'
									onClick={deleteNodeClickHandler}
								>
									<DeleteIcon />
								</DeleteNode>
							)}
							{isOnHover && !node.lesson && level < 2 && (
								<AddChildNode
									title='Aggiungi figlio'
									onClick={addChildNodeHandler}
								>
									<AddBoxIcon />
								</AddChildNode>
							)}
						</>
					)}
				</RightSection>
			</ContentNodeBox>
			{children.length > 0 && isExpanded && (
				<>
					{children
						.sort((a, b) => {
							if (a.pos > b.pos) return 1;
							if (a.pos < b.pos) return -1;
							return 0;
						})
						.map((n) => {
							return (
								<ContentNode
									key={n.id}
									node={n}
									nodes={nodes}
									product={product}
									moveNode={moveNode}
									updateLocalNode={updateLocalNode}
									reArrangePositions={reArrangePositions}
									addNode={addNode}
									deleteNode={deleteNode}
									addLessonToNode={addLessonToNode}
								/>
							);
						})}
					{/* <AddNode onClick={addChildNodeHandler}>
						<AddBoxIcon /> Aggiungi Nodo
					</AddNode> */}
				</>
			)}
			{isVideoPreviewModalVisible && (
				<VideoPreviewModal
					onCloseButtonModal={closeVideoPreviewModal}
					url={node.lesson.video.url}
				/>
			)}
			{isModifyLessonModalVisible && (
				<ModifyLessonModal
					onCloseButtonModal={closeModifyLessonModal}
					node={node}
					updateLocalNode={updateLocalNode}
					spreakerBrandCode={product?.type === 'THEMATIC_BROADCAST' && product?.productThematicCall.type === 'ESPERIENZA' ? '_Esperienze' : product?.type === 'THEMATIC_BROADCAST' && product?.productThematicCall.type !== 'ESPERIENZA' ? '_Aule' : '_Corsi_Servizi'}
				/>
			)}
		</>
	);
};

export default ContentNode;
