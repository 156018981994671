import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Category } from 'types/Category';
import { PaymentType } from 'types/PaymentType';
import { Pool } from 'types/Pool';
import { Product } from 'types/Product';
import { Level } from 'types/Level';
import { Role } from 'types/Role';
import { SubCategory } from 'types/SubCategory';
import { User } from 'types/User';

interface GlobalState {
	allRoles: Role[] | undefined;
	allProductCategories: Category[] | undefined;
	allProductSubCategories: SubCategory[] | undefined;
	allProducts: Product[] | undefined;
	allLevels: Level[] | undefined;
	staffUsers: Partial<User>[] | undefined;
	authorPoolsUsers: Partial<User>[] | undefined;
	authorPools: Pool[] | undefined;
	paymentTypes: PaymentType[] | undefined;
}

const initialState: GlobalState = {
	allRoles: undefined,
	allProductCategories: undefined,
	allProductSubCategories: undefined,
	allProducts: undefined,
	allLevels: undefined,
	staffUsers: undefined,
	authorPoolsUsers: undefined,
	authorPools: undefined,
	paymentTypes: undefined,
};

const globalSlice = createSlice({
	name: 'global',
	initialState,

	reducers: {
		setAllRoles: (state, action: PayloadAction<Role[] | undefined>) => {
			state.allRoles = action.payload;
		},
		setAllCategories: (state, action: PayloadAction<Category[] | undefined>) => {
			state.allProductCategories = action.payload;
		},
		setAllSubCategories: (state, action: PayloadAction<SubCategory[] | undefined>) => {
			state.allProductSubCategories = action.payload;
		},
		setAllProducts: (state, action: PayloadAction<Product[] | undefined>) => {
			state.allProducts = action.payload;
		},
		setAllLevels: (state, action: PayloadAction<Level[] | undefined>) => {
			state.allLevels = action.payload;
		},
		setStaffUsers: (state, action: PayloadAction<Partial<User>[] | undefined>) => {
			state.staffUsers = action.payload;
		},
		setAuthorPoolsUsers: (state, action: PayloadAction<Partial<User>[] | undefined>) => {
			state.authorPoolsUsers = action.payload;
		},
		setAuthorPools: (state, action: PayloadAction<Pool[] | undefined>) => {
			state.authorPools = action.payload;
		},
		setPaymentTypes: (state, action: PayloadAction<PaymentType[] | undefined>) => {
			state.paymentTypes = action.payload;
		},
		addProduct: (state, action: PayloadAction<Product>) => {
			state.allProducts?.push(action.payload);
		},
	},
});

// slice actions mapping
export const { setAllRoles, setAllCategories, setAllSubCategories, setAllProducts, setAllLevels, setStaffUsers, setAuthorPoolsUsers, setAuthorPools, setPaymentTypes, addProduct } =
	globalSlice.actions;

// reducer for store
export default globalSlice.reducer;
