import styled from 'styled-components';
import devices from 'style/breakpoints';
import colors from 'style/palette/palette';

interface CircularButtonProps {
    size: number;
    isCircular: boolean;
    isMB: boolean;
    isTCR: boolean;
    isOMK: boolean;
    onClick: () => void; // Assuming you have an onClick handler
}


export const CircularImage = styled.img<{ size: number }>`
    width: ${(props) => props.size}px;
    height: ${(props) => props.size}px;
    border-radius: 50%;   
    border: 1px solid white; 
    object-fit: cover;  
    transform: scale(0.5); 
    cursor: pointer;
`;

export const CircularButton = styled.button<CircularButtonProps>`
  width: ${(props) => (props.isOMK ? props.isCircular ? '45px' : '97px' : props.size+'px')};
  height: ${(props) => props.size}px;
  border-radius: ${(props) => (props.isCircular ? '50%' : '0')};
  padding: 0;
  margin-right: ${(props) => props.isMB && !props.isCircular ? '2px' : '8px'};
  border: ${(props) => (props.isCircular && !props.isOMK ? '1px solid #8c8c8c' : '0')};
  overflow: hidden;
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: 100%;
    /* object-fit: ${(props) => (props.isCircular ? 'cover' : 'contain')};; */
    border-radius: ${(props) => (props.isCircular ? '50%' : '0')};
    transform: ${(props) => (props.isCircular && props.isMB ? 'scale(0.75)' : 'none')};
    transform: ${(props) => (!props.isCircular && props.isTCR ? 'scale(0.5)' : 'none')};

  }
`;

export const BrandCode = styled.div`
  color: ${colors.primaryColor}
`;