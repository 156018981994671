import { FC, useEffect, useRef, useState } from 'react';

// redux
import { addSnackbar } from 'redux/actions/actions-snackbar';


//hooks
import useAppDispatch from 'hooks/useAppDispatch';

// type
import { VideoUpdateElementProps } from './VideoUpdateElement.component.d';
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';

//components
import DeleteElementModal from 'components/DeleteElementModal/DeleteElementModal.component';

//utils
import { createNewBlock, createNewElement, updateImageIntoElements, updateVideoIntoElements, updateVideoOnSalesData, deleteVideoOnSalesData, updateVideoPosterOnSalesData, deleteVideoPosterOnSalesData, uploadImageIntoElements, uploadVideoOnSales } from 'pages/PublicProductPages/services/PublicProductPage.services';


// style
import { 
    CardContent,
    ButtonRow,
    RowContent,
    CloseAndCancel,
    SaveAndCloseButton,
    DeleteElementButton,
    UploadPosterButton,
    UrlInput,
    UrlLabel,    
    ColumnContent,
    ActualPicture,
    VideoButtonBox,
    Label,
    AttachNewFileButton,
    AttachmentNewFile,
    RemoveButton
} from './style/VideoUpdateElement.style';
import { AxiosProgressEvent } from 'axios';


const VideoUpdateElement: FC<VideoUpdateElementProps> = ({
    productId,
    webpageId,
    blockId,
    elementId,
    isRequired,
    newBlockPosition,
    newBlockLayout,
    isNewElement,
    numberOfElements,
    typeOfLayout,
    update,
    updateData,
    onClose,
    videoUrl,
    pictureUrl,
    spreakerBrandCode
}) => {
    
    const MEDIA_ALLOWED_FILE_TYPES = ['mp4'];//, 'mp3', 'm4a'];

    const [urlInputValue, setUrlInputValue] = useState<string>('');
    const [pictureUrlValue, setPictureUrlValue] = useState<string>('');
    const [isRemoveElementModalVisible, setIsRemoveElementModalVisible] = useState<boolean>(false);
    const [imageToUpload, setImageToUpload] = useState<File>();
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [uploadProgress, setUploadProgress] = useState<number>(0);

    const attachmentNewFileInput = useRef<HTMLInputElement>(null);

    const onDeleteButtonHandler = () => setIsRemoveElementModalVisible(true);

    const dispatch = useAppDispatch();
    const videoRef = useRef<HTMLInputElement | null>(null);
    
	const extractFilename = (filePath: string) => {
		const segments = filePath.split('/');
		const filename = segments[segments.length - 1];
		return (filename !== '') ? filename : filePath;
	  };

    const onCancelClickHandler = () => {
        onClose();
    };

    const handleSingleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            if (!MEDIA_ALLOWED_FILE_TYPES.includes(file.name.split('.').pop()!)) {
                const snackbarOptions: SnackbarOptions = {
                    message: 'Tipo di file non consentito',
                    type: 'error',
                };
                dispatch(addSnackbar(snackbarOptions));
                return;
            }
            setIsUploading(true);
            const fileName = extractFilename(file.name);
            var newFormData = new FormData();
            newFormData.append('file', file, fileName);
            try {
                const uploaded = await uploadVideoOnSales(
                    Number(productId), 
                    newFormData, 
                    undefined, 
                    spreakerBrandCode,
                    (progressEvent: AxiosProgressEvent) => {
                        if (progressEvent.total) { 
                            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                            setUploadProgress(percentCompleted);
                        }
                    }
                );
                setUrlInputValue(uploaded.url);
                const snackbarOptions: SnackbarOptions = {
                    message: 'File caricato con successo',
                    type: 'success',
                };
                dispatch(addSnackbar(snackbarOptions));
            } catch (error: any) {
                console.error("Errore durante il caricamento:", error);
                const snackbarOptions: SnackbarOptions = {
                    message: 'Errore durante il caricamento: '+error?.message,
                    type: 'error',
                };
                dispatch(addSnackbar(snackbarOptions));
                setUploadProgress(-1); 
            }
            setIsUploading(false);
        }
    };

    const handleFileUpload = (newFile: FileList | null) => {

        if (!newFile) return;
        setImageToUpload(newFile[0]);
    };

    const handleFileButton = () => {
        if (attachmentNewFileInput.current) {
            attachmentNewFileInput.current.click();
        }
    }

    const onSaveAndCloseHandler = async () => {
        
        console.log("file:", imageToUpload);
        console.log("P id:", productId);
        
        if (isNewElement && (newBlockLayout !== undefined)) {

            await createNewVideoElement();
            if (updateData) await updateData();
            onCancelClickHandler();        
            return;
        }
        if ((videoRef.current !== null) && (videoRef.current.value !== '')) await updateVideoUrl();
        if ((videoRef.current !== null) && (videoRef.current.value === '')) await deleteVideoUrl();
        if ((imageToUpload) && isRequired) await updateVideoPoster();
        if (updateData) await updateData();
        onClose();
    };

    const updateVideoUrl = async () => {

        // to do snackbar and spinner
        try {
            
            if (videoRef.current === null) return;            
            if (productId) await updateVideoOnSalesData(productId, videoRef.current.value);
            if (webpageId && elementId) await updateVideoIntoElements(webpageId, elementId, videoRef.current.value);
        } catch (error: any) {
            console.warn("ERROR:", error);
        }
    };

    const deleteVideoUrl = async () => {

        // to do snackbar and spinner
        try {
            
            if (videoRef.current === null) return;            
            if (productId) {
                await deleteVideoOnSalesData(productId);
                setUrlInputValue('');
            }
        } catch (error: any) {
            console.warn("ERROR:", error);
        }
    };

    const updateVideoPoster = async () => {

        // to do snackbar and spinner
        try {
            
            if (imageToUpload === undefined) return;
            if (productId) await updateVideoPosterOnSalesData(productId, imageToUpload);
        } catch (error: any) {
            console.warn("ERROR:", error);
        }
    };

    const deleteVideoPoster = async () => {
        try {
            if (productId) {
                await deleteVideoPosterOnSalesData(productId);
                setPictureUrlValue('');
            }
        } catch (error: any) {
            console.warn("ERROR:", error);
        }
    };

    const handleRemoveFileButton = () => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm("Sei sicuro di voler rimuovere il file?")) {
            deleteVideoUrl();
        }
    };

    const handleRemoveImageButton = () => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm("Sei sicuro di voler rimuovere l'immagine?")) {
            deleteVideoPoster();
        }
    };

    const createNewVideoElement = async () => {

        // to do snackbar and spinner
        try {

            if (webpageId === undefined) return;
            if (newBlockPosition === undefined) return;
            if (newBlockLayout === undefined) return;
            const newBlock = await createNewBlock(webpageId, newBlockPosition, newBlockLayout);
            
            if (newBlock.id === undefined) return;
            const newElement = await createNewElement(newBlock.id, 'VIDEO', 0);
            
            if (newElement.id === undefined) return;
            if (videoRef.current === null) return;
            await updateVideoIntoElements(webpageId, newElement.id, videoRef.current.value);            
        } catch (error: any) {
            console.warn("ERROR:", error);
        }
    };

    const updateUrlInputValue = (e: React.ChangeEvent<HTMLInputElement>) => setUrlInputValue(e.target.value);

    useEffect(() => {
        if (videoUrl) setUrlInputValue(videoUrl);
    }, [videoUrl]);

    useEffect(() => {
        if (pictureUrl) setPictureUrlValue(pictureUrl);
    }, [pictureUrl]);

    return (
        <>
            <CardContent>
                <RowContent>
                    <UrlLabel>
                        URL del video/audio (modificabile):

                        <UrlInput 
                            ref={videoRef}
                            type={"text"}
                            value={urlInputValue}
                            onChange={updateUrlInputValue}
                        />

                        <VideoButtonBox>
                            <Label>
                            {"Tipi file consentiti: "+MEDIA_ALLOWED_FILE_TYPES.join(', ')}
                            </Label>
                            <AttachNewFileButton 
                                disabled={isUploading} 
                                onClick={handleFileButton}
                            >
                                {isUploading ? uploadProgress < 100 ? `Caricamento... ${uploadProgress}%` : 'Attendere...' : uploadProgress === -1 ? 'Errore!' : 'Carica nuovo file'}
                            </AttachNewFileButton>
                            <RemoveButton onClick={handleRemoveFileButton}>&#10006;</RemoveButton>
                        </VideoButtonBox>

                    </UrlLabel>

                    <AttachmentNewFile
                        ref={attachmentNewFileInput}
                        onChange={handleSingleFileChange}
                        type="file"
                        accept={MEDIA_ALLOWED_FILE_TYPES ? MEDIA_ALLOWED_FILE_TYPES.map(ext => `.${ext}`).join(',') : undefined}
                    />

                    {isRequired && 
                    !isNewElement && 
                    (newBlockLayout === undefined) && (
                        // <UrlLabel>
                        //     URL dell'immagine nuova (poster)

                        //     <UrlInput 
                        //         ref={posterRef}
                        //     />
                        // </UrlLabel>
                        <ColumnContent>
                            <UrlLabel>
                                <ActualPicture>
                                    URL dell'immagine: <UrlInput type="text" value={pictureUrlValue} />
                                </ActualPicture>

                                Carica nuova immagine

                                <UploadPosterButton onClick={() => document.getElementById('image')?.click()} >
                                    Sfoglia...
                                </UploadPosterButton>

                                <UrlInput 
                                    type="file"
                                    id="image"
                                    accept=".png, .jpeg, .jpg"
                                    onChange={(e) => handleFileUpload(e.target.files)}
                                    style={{ display: 'none' }}
                                />

                                <RemoveButton onClick={handleRemoveImageButton}>&#10006;</RemoveButton>
                            </UrlLabel>

                            {imageToUpload ? imageToUpload.name : "Nessuna immagine selezionata"}
                        </ColumnContent>
                        )
                    }
                </RowContent>

                <ButtonRow>
                    {!isRequired && 
                    !isNewElement && 
                    (newBlockLayout === undefined) && (
                        <DeleteElementButton
                            onClick={onDeleteButtonHandler}
                        >
                            Elimina il Video
                        </DeleteElementButton>
                        )
                    }

                    <CloseAndCancel
                            onClick={onCancelClickHandler}
                    >
                        Annulla e Chiudi
                    </CloseAndCancel>

                    <SaveAndCloseButton
                            onClick={onSaveAndCloseHandler}
                    >
                        Salva e Chiudi
                    </SaveAndCloseButton>
                </ButtonRow>
            </CardContent>

            {isRemoveElementModalVisible && (
                <DeleteElementModal 
                    onCloseButtonModal={onCancelClickHandler}
                    elementId={elementId}
                    updateData={updateData}
                    typeOfLayout={typeOfLayout}
                    numberOfElements={numberOfElements}
                />)
            }
        </>
    );
};

export default VideoUpdateElement;
