// import { callGET } from 'network/network';

//types
import { Brand } from 'types/Brand';

// const _BRANDS_PATH = 'v1/brands'; 

export const getBrandsList = async () => {

    const responseTest: Brand[] = [
        {
            "id": 1,
            "name": "Liberi dal Lavoro",
            "domain": "ldl-test.eu",
            "code": "LDL",
            "logo": {
                "id": 5427,
                "fileName": "ico-big-ldl.svg",
                "url": "https://www.viveredipoker.it/ldlapifile/brands/logos/ico-big-ldl.svg",
                "fileType": {
                    "id": 29,
                    "name": "Brand Logo",
                    "relativePath": "brands/logos"
                }
            },
            "brandChannelId": 47,
            "personalChannelId": 48
        },
        {
            "id": 2,
            "name": "Tutorial Crypto",
            "domain": "tutorialcrypto-test.link",
            "code": "TCR",
            "logo": {
                "id": 5429,
                "fileName": "ico-big-tc.svg",
                "url": "https://www.viveredipoker.it/ldlapifile/brands/logos/ico-big-tc.svg",
                "fileType": {
                    "id": 29,
                    "name": "Brand Logo",
                    "relativePath": "brands/logos"
                }
            },
            "brandChannelId": 51,
            "personalChannelId": 54
        },
        {
            "id": 3,
            "name": "La Voce della Ricchezza",
            "domain": "vdrtest.eu",
            "code": "VDR",
            "logo": {
                "id": 5428,
                "fileName": "ico-big-lvdr.svg",
                "url": "https://www.viveredipoker.it/ldlapifile/brands/logos/ico-big-lvdr.svg",
                "fileType": {
                    "id": 29,
                    "name": "Brand Logo",
                    "relativePath": "brands/logos"
                }
            },
            "brandChannelId": 53,
            "personalChannelId": 56
        },
        {
            "id": 4,
            "name": "Missione Benessere",
            "domain": "mbtest.it",
            "code": "MB",
            "logo": {
                "id": 5430,
                "fileName": "ico-big-mb.svg",
                "url": "https://www.viveredipoker.it/ldlapifile/brands/logos/ico-big-mb.svg",
                "fileType": {
                    "id": 29,
                    "name": "Brand Logo",
                    "relativePath": "brands/logos"
                }
            },
            "brandChannelId": 52,
            "personalChannelId": 55
        },
        {
            "id": 5,
            "name": "Omnimarketing",
            "domain": "omk-test.eu",
            "code": "OMK",
            "logo": {
                "id": 18359,
                "fileName": "ico-big-omk.svg",
                "url": "https://www.viveredipoker.it/ldlapifile/brands/logos/ico-big-omk.webp",
                "fileType": {
                    "id": 29,
                    "name": "Brand Logo",
                    "relativePath": "brands/logos"
                }
            },
            "brandChannelId": 62,
            "personalChannelId": 61
        }
    ];
    
    const responseProduction: Brand[] = [
        {
            "id": 1,
            "name": "Liberi dal Lavoro",
            "domain": "liberidalavoro.it",
            "code": "LDL",
            "logo": {
                "id": 14424,
                "fileName": "ico-big-ldl.svg",
                "url": "https://www.viveredipoker.it/ldlapifile/brands/logos/ico-big-ldl.svg",
                "fileType": {
                    "id": 29,
                    "name": "Brand Logo",
                    "relativePath": "brands/logos"
                }
            },
            "brandChannelId": 41,
            "personalChannelId": 42
        },
        {
            "id": 2,
            "name": "Tutorial Crypto",
            "domain": "tutorialcrypto.com",
            "code": "TCR",
            "logo": {
                "id": 14426,
                "fileName": "ico-big-tc.svg",
                "url": "https://www.viveredipoker.it/ldlapifile/brands/logos/ico-big-tc.svg",
                "fileType": {
                    "id": 29,
                    "name": "Brand Logo",
                    "relativePath": "brands/logos"
                }
            },
            "brandChannelId": 48,
            "personalChannelId": 49
        },
        {
            "id": 3,
            "name": "La Voce della Ricchezza",
            "domain": "lavocedellaricchezza.it",
            "code": "VDR",
            "logo": {
                "id": 14425,
                "fileName": "ico-big-lvdr.svg",
                "url": "https://www.viveredipoker.it/ldlapifile/brands/logos/ico-big-lvdr.svg",
                "fileType": {
                    "id": 29,
                    "name": "Brand Logo",
                    "relativePath": "brands/logos"
                }
            },
            "brandChannelId": 50,
            "personalChannelId": 51
        },
        {
            "id": 4,
            "name": "Missione Benessere",
            "domain": "app.gruppomissionebenessere.it",
            "code": "MB",
            "logo": {
                "id": 14427,
                "fileName": "ico-big-mb.svg",
                "url": "https://www.viveredipoker.it/ldlapifile/brands/logos/ico-big-mb.svg",
                "fileType": {
                    "id": 29,
                    "name": "Brand Logo",
                    "relativePath": "brands/logos"
                }
            },
            "brandChannelId": 52,
            "personalChannelId": 53
        },
        {
            "id": 5,
            "name": "Omnimarketing",
            "domain": "omnimarketing.it",
            "code": "OMK",
            "logo": {
                "id": 24900,
                "fileName": "ico-big-omk.svg",
                "url": "https://www.viveredipoker.it/ldlapifile/brands/logos/ico-big-omk.webp",
                "fileType": {
                    "id": 29,
                    "name": "Brand Logo",
                    "relativePath": "brands/logos"
                }
            },
            "brandChannelId": 66,
            "personalChannelId": 65
        }
    ];

    const domain = window.location.hostname;
    if (!domain.includes("test")) return responseProduction;
    return responseTest;

//     try {

//         const response = await callGET(_BRANDS_PATH, true);

//         if (response === undefined || !response) {
//             throw new Error();
//         }

//         return response;
        
//     } catch (error: any) {
//         throw error;
//     }
};

