import { Config } from './config.d';


const getEnvironment = () => {
    const url = window.location.hostname;

    if (url.includes('localhost')) return 'development';
    if (url.includes('test')) return 'test';
    return 'production';
};

const getDomain = () => {
    const domain = window.location.hostname.replace('backoffice.','');;
    const port = window.location.port !== '' ? ':'+window.location.port : '';

    return domain+port;
}

const getAPIUrl = () => {
    const domain = getDomain();

    if (!domain.includes("localhost")) {
        return 'https://api.'+domain+"/";
    } else {
        return 'https://api.ldl-test.eu/';
    }
}

const getVideoAPIUrl = () => {
    const domain = getDomain();

    if (!domain.includes("localhost")) {
        if (domain.includes("liberidalavoro.it") || domain.includes("ldl-test.eu")) {
            return 'https://axe0ebg0mph.'+domain+"/";
        } 
        if (domain.includes("tutorialcrypto.com") || domain.includes("tutorialcrypto-test.link")) {
            return 'https://vzgegn8vzz6.'+domain+"/";
        }
        if (domain.includes("lavocedellaricchezza.it") || domain.includes("vdrtest.eu")) {
            return 'https://KWR9xFkgiMr.'+domain+"/";
        }
        if (domain.includes("gruppomissionebenessere.it") || domain.includes("mbtest.it")) {
            return 'https://pTRbzkBvZ9E.'+domain+"/";
        }
        if (domain.includes("omnimarketing.it") || domain.includes("omk-test.eu")) {
            return 'https://N3a4RMjyVha.'+domain+"/";
        }
        return 'https://axe0ebg0mph.'+domain+"/";
    } else {
        return 'https://axe0ebg0mph.ldl-test.eu/';
    }
}

const getUrl = () => {
    const domain = getDomain();

    return 'https://backoffice.'+domain+"/";
}

const config: Config = {

    environment: getEnvironment(),
    baseTestFrontendUrl: "https://ldl-frontend-v2-test-build.s3.eu-central-1.amazonaws.com/",
    baseProductionFrontendUrl: "https://ldl-frontend-v2-prod-build.s3.eu-central-1.amazonaws.com/",
    baseTestURL: getAPIUrl(),
    baseTestVideoURL: getVideoAPIUrl(),
    // baseTestURL: "http://localhost:8081/",
    baseProductionURL: getAPIUrl(),
    baseProductionVideoURL: getVideoAPIUrl(),
    baseOldTestURL: "https://ldl-test.eu/api/",
    baseOldProductionURL: "https://liberidalavoro.it/api/",
    testUrl: getUrl(),
    productionUrl: getUrl(),
    localStoragePrefix: "LDLFrontEnd_",
    defaultBrand: {
        "id": 1,
        "name": "Liberi dal Lavoro",
        "domain": "ldl-test.eu",
        "code": "LDL",
        "logo": {
            "id": 5427,
            "fileName": "ico-big-ldl.svg",
            "url": "https://www.viveredipoker.it/ldlapifile/brands/logos/ico-big-ldl.svg",
            "fileType": {
                "id": 29,
                "name": "Brand Logo",
                "relativePath": "brands/logos"
            }
        },
        "brandChannelId": 47,
        "personalChannelId": 48
    },
    
    socialLinks: {
        
        facebook: "https://www.facebook.com/LiberiDalLavoro",
        fbGroupTestID: "1101743300450841",
        fbGroupID: "492540681127066",
        telegram: "https://t.me/LiberiDalLavoroOfficial",
        youtube: "https://www.youtube.com/LiberiDalLavoro",
        instagram: "https://www.instagram.com/liberi_dal_lavoro/",
        twitch: "https://www.twitch.tv/liberidallavoro",
        tikTok: "https://www.tiktok.com/@liberidallavoro_official"    
    }
};

export default config;
