import { callPUT } from 'network/network';

import { getBrand } from 'configs/brandMap/brandMap.helper';

import { spreakerPlaylists } from 'configs/spreaker/spreaker-config';
import { AxiosProgressEvent } from 'axios';

const _AUDIO_EXTENSIONS = ['mp3', 'm4a'];

const _PRODUCT_VIDEO_PATH = 'v1/products/{productId}/thematicbroadcast/recordedvideo';



export const uploadRecordedVideo = async (productId: number, form?: FormData, videoUrl?: string, spreakerBrandCode?: string, onUploadProgress?: (progressEvent: AxiosProgressEvent) => void) => {
    const brand = getBrand();
    if (!brand) return;
    try {
        var params: any = {
            brandCode: brand.code
        }
        if (videoUrl) {
            params.url = videoUrl;
        }
        if (spreakerBrandCode && form) {
            const file = form.get('file');
            if (file instanceof File && _AUDIO_EXTENSIONS.includes(file.name.split('.').pop()!)) {
                params.spreakerShowId = spreakerPlaylists.find((playlist) => playlist.brandCode.includes(brand.code + spreakerBrandCode))?.spreakerShowId;
                params.spreakerShowKey = spreakerPlaylists.find((playlist) => playlist.brandCode.includes(brand.code + spreakerBrandCode))?.spreakerShowKey;
                if (!params.spreakerShowId || !params.spreakerShowKey) {
                    throw new Error('Spreaker config not found');
                }
            }
        }
        const response = await callPUT(_PRODUCT_VIDEO_PATH.replace(/{productId}/g,
        productId.toString()), form ? form : {}, true, params, onUploadProgress, true);

        if (response === undefined || !response) {
            throw new Error();
        }

        return response;
    } catch (error: any) {
        throw error;
    }
};