import { callPUT } from 'network/network';
import { Brand } from 'types/Brand';

import { getBrand } from 'configs/brandMap/brandMap.helper';

const _MULTIBRAND_API_URL = `v1/products/{id}/brands`;

export const assignBrandToProduct = async (productId: string, brands: Partial<Brand>[]) => {
    const brand = getBrand();
    if (!brand) return;
    try {
        const response = await callPUT(_MULTIBRAND_API_URL.replace('{id}', productId), brands, true, { brandCode: brand.code });
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};
