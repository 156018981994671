type SpreakerPlaylist = {
    brandCode: string;
    spreakerShowId: string;
    spreakerShowKey: string;
}

export const spreakerPlaylists: SpreakerPlaylist[] = [
	{
		brandCode: '01_LDL_Corsi_Servizi',
		spreakerShowId: '6278651',
		spreakerShowKey: 'aMp5FqFk1Jb9',
    },
    {
        brandCode: '02_TCR_Corsi_Servizi',
        spreakerShowId: '6293907',
        spreakerShowKey: 'BrmhtuADytyJ',
    },
    {
        brandCode: '03_VDR_Corsi_Servizi',
        spreakerShowId: '6296431',
        spreakerShowKey: 'r2EbQzs4Ra8A',
    },
    {
        brandCode: '04_MB_Corsi_Servizi',
        spreakerShowId: '6296443',
        spreakerShowKey: 'cEWqVFcFpt4G',
    },
    {
        brandCode: '01_LDL_Ven_Corsi_Servizi',
        spreakerShowId: '6278665',
        spreakerShowKey: 'EdChQjzHMgJ7',
    },
    {
        brandCode: '02_TCR_Ven_Corsi_Servizi',
        spreakerShowId: '6296424',
        spreakerShowKey: '3BtHbvJJV1Vf',
    },
    {
        brandCode: '03_VDR_Ven_Corsi_Servizi',
        spreakerShowId: '6296436',
        spreakerShowKey: 'gE8NsdFhqVds',
    },
    {
        brandCode: '04_MB_Ven_Corsi_Servizi',
        spreakerShowId: '6296448',
        spreakerShowKey: 'FzsWPVHCdcba',
    },
    {
        brandCode: '01_LDL_Aule',
        spreakerShowId: '6278660',
        spreakerShowKey: 'bJHCJr63rWWV',
    },
    {
        brandCode: '02_TCR_Aule',
        spreakerShowId: '6293903',
        spreakerShowKey: 'S3uq4B3a4AJW',
    },
    {
        brandCode: '03_VDR_Aule',
        spreakerShowId: '6296430',
        spreakerShowKey: '7qPtnHv19des',
    },
    {
        brandCode: '04_MB_Aule',
        spreakerShowId: '6296441',
        spreakerShowKey: 'FyVqAUntttv5',
    },
    {
        brandCode: '01_LDL_Ven_Aule',
        spreakerShowId: '6278670',
        spreakerShowKey: 'Ydek6kbr4Nf5',
    },
    {
        brandCode: '02_TCR_Ven_Aule',
        spreakerShowId: '6296417',
        spreakerShowKey: 'cu2U35ujVrZg',
    },
    {
        brandCode: '03_VDR_Ven_Aule',
        spreakerShowId: '6296433',
        spreakerShowKey: 'zBZtZjz3JCGr',
    },
    {
        brandCode: '04_MB_Ven_Aule',
        spreakerShowId: '6296446',
        spreakerShowKey: 'ZB3PBX4CDsKV',
    },
    {
        brandCode: '01_LDL_Esperienze',
        spreakerShowId: '6278662',
        spreakerShowKey: 'KYskSaKqNTwR',
    },
    {
        brandCode: '02_TCR_Esperienze',
        spreakerShowId: '6296423',
        spreakerShowKey: 'TJqU5KN2BBU9',
    },
    {
        brandCode: '03_VDR_Esperienze',
        spreakerShowId: '6296432',
        spreakerShowKey: 'dVuSHXQrV9hf',
    },
    {
        brandCode: '04_MB_Esperienze',
        spreakerShowId: '6296444',
        spreakerShowKey: 'teHeXzbXFY55',
    },
    {
        brandCode: '01_LDL_Ven_Esperienze',
        spreakerShowId: '6278676',
        spreakerShowKey: 'TevPGH4P6AJc',
    },
    {
        brandCode: '02_TCR_Ven_Esperienze',
        spreakerShowId: '6296427',
        spreakerShowKey: 's1adE7zvC5Cn',
    },
    {
        brandCode: '03_VDR_Ven_Esperienze',
        spreakerShowId: '6296439',
        spreakerShowKey: 'XBrw9UbJcybu',
    },
    {
        brandCode: '04_MB_Ven_Esperienze',
        spreakerShowId: '6296450',
        spreakerShowKey: 'UuAstWUvK3Sm',
    },
];
