import { callPUT } from 'network/network';

import { getBrand } from 'configs/brandMap/brandMap.helper';

import { spreakerPlaylists } from 'configs/spreaker/spreaker-config';

import { AxiosProgressEvent } from 'axios';

const _AUDIO_EXTENSIONS = ['mp3', 'm4a'];

const _LESSON_ATTACHMENT_PATH = 'v1/courses/nodes/lesson/{lessonId}/attachments';
const _LESSON_VIDEO_PATH = 'v1/courses/nodes/lesson/{lessonId}/video';

const brand = getBrand();

export const uploadAttachment = async (lessonId: number, form?: FormData, url?: string) => {
    const brand = getBrand();
    if (!brand) return;
    try {
        const response = await callPUT(_LESSON_ATTACHMENT_PATH.replace(/{lessonId}/g,
        lessonId.toString()), form ? form : {}, true, { url: url, brandCode: brand.code});

        if (response === undefined || !response) {
            throw new Error();
        }

        return response;
    } catch (error: any) {
        throw error;
    }
};

export const uploadVideo = async (
    lessonId: number,
    form?: FormData,
    url?: string,
    spreakerBrandCode?: string,
    onUploadProgress?: (progressEvent: AxiosProgressEvent) => void // Confermato il tipo corretto
) => {
    const brand = getBrand();
    if (!brand) return;
    try {
        var params: any = {
            brandCode: brand.code
        };
        if (url) {
            params.url = url;
        }
        if (spreakerBrandCode && form) {
            const file = form.get('file');
            if (file instanceof File && _AUDIO_EXTENSIONS.includes(file.name.split('.').pop()!)) {
                params.spreakerShowId = spreakerPlaylists.find((playlist) => playlist.brandCode.includes(brand.code + spreakerBrandCode))?.spreakerShowId;
                params.spreakerShowKey = spreakerPlaylists.find((playlist) => playlist.brandCode.includes(brand.code + spreakerBrandCode))?.spreakerShowKey;
                if (!params.spreakerShowId || !params.spreakerShowKey) {
                    throw new Error('Spreaker config non trovata');
                }
            }
        }
        const response = await callPUT(
            _LESSON_VIDEO_PATH.replace(/{lessonId}/g, lessonId.toString()),
            form ? form : {},
            true,
            params,
            onUploadProgress
        );

        if (response === undefined || !response) {
            throw new Error();
        }

        return response;
    } catch (error: any) {
        throw error;
    }
};