import { FC, useRef, useState } from 'react';
import { Label } from '../style/ModifyLessonModal.component.style';

//redux
import { useDispatch } from 'react-redux';
import { addSnackbar } from 'redux/actions/actions-snackbar';

//hooks
import useAppDispatch from 'hooks/useAppDispatch';

//types
import { LessonVideoProps } from './LessonVideo.component.d';
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';
//utils
import { uploadVideo } from '../LessonAttachments/services/LessonAttachments.services';

//style
import { LessonVideoBox, VideoUrl, VideoUrlBox, AttachNewFileButton, AttachmentNewFile, VideoButtonBox } from './style/Lessonvideo.component.style';
import { AxiosProgressEvent } from 'axios';

const LessonVideo: FC<LessonVideoProps> = ({ node, videoUrl, setLocalVideoUrl, allowedFileTypes, spreakerBrandCode }) => {

	const [isUploading, setIsUploading] = useState(false);
	const [uploadProgress, setUploadProgress] = useState(0);

	const dispatch = useAppDispatch();

	const attachmentNewFileInput = useRef<HTMLInputElement>(null);

	const extractFilename = (filePath: string) => {
		const segments = filePath.split('/');
		const filename = segments[segments.length - 1];
		return (filename !== '') ? filename : filePath;
	};

	const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		setLocalVideoUrl(event.currentTarget.value);
	};

	const handleFileButton = () => {
		if (attachmentNewFileInput.current) {
			attachmentNewFileInput.current.click();
		}
	}

	const handleSingleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0];
		if (file) {
			if (allowedFileTypes && !allowedFileTypes.includes(file.name.split('.').pop()!)) {
				const snackbarOptions: SnackbarOptions = {
					message: 'Tipo di file non consentito',
					type: 'error',
				};
				dispatch(addSnackbar(snackbarOptions));
				return;
			}
			setIsUploading(true);
			setUploadProgress(0);
			const fileName = extractFilename(file.name);
			const newFormData = new FormData();
			newFormData.append('file', file, fileName);
			try {
				const uploaded = await uploadVideo(
					node.id,
					newFormData,
					undefined,
					spreakerBrandCode,
					(progressEvent: AxiosProgressEvent) => {
						if (progressEvent.total) { // Verifica che 'total' sia definito
							const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
							setUploadProgress(percentCompleted);
						}
					}
				);
				setLocalVideoUrl(uploaded.video.url);
				const snackbarOptions: SnackbarOptions = {
					message: 'File caricato con successo',
					type: 'success',
				};
				dispatch(addSnackbar(snackbarOptions));
			} catch (error: any) {
				const snackbarOptions: SnackbarOptions = {
					message: 'Errore durante il caricamento: '+error?.message,
					type: 'error',
				};
				dispatch(addSnackbar(snackbarOptions));
				setUploadProgress(-1);
			} finally {
				setIsUploading(false);
			}
		}
	};

	return (
		<LessonVideoBox>
			<Label>Video/Audio</Label>
			<VideoUrlBox>
			<VideoUrl
				value={videoUrl}
				onChange={onChangeHandler}
				placeholder='URL del video/audio'
			></VideoUrl>

			<VideoButtonBox>
				<Label>
				{allowedFileTypes && "Tipi file consentiti: "+allowedFileTypes.join(', ')}
				</Label>
				<AttachNewFileButton disabled={isUploading} onClick={handleFileButton}>
				{isUploading ? uploadProgress < 100 ? `Caricamento... ${uploadProgress}%` : 'Attendere...' : uploadProgress === -1 ? 'Errore!' : 'Carica nuovo file'}
				</AttachNewFileButton>
			
			</VideoButtonBox>
			
			</VideoUrlBox>

			<AttachmentNewFile
				ref={attachmentNewFileInput}
				onChange={handleSingleFileChange}
				type="file"
				accept={allowedFileTypes ? allowedFileTypes.map(ext => `.${ext}`).join(',') : undefined}
			/>
		</LessonVideoBox>
	);
};

export default LessonVideo;
