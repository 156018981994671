import { FC, useEffect, useState } from 'react';

// utility
import { createWebPageForAProduct, updateElement } from 'pages/PublicProductPages/services/PublicProductPage.services';
import config from 'configs/config';

// components
import MediaPlayer from 'components/MediaPlayer';
import EditableRow from 'components/EditableComponents/EditableRow';
import EditableGroup from 'components/EditableComponents/EditableGroup';
import EditableTextArea from 'components/EditableComponents/EditableTextArea';
import EditableVideo from 'components/EditableComponents/EditableVideo';
import EditableImage from 'components/EditableComponents/EditableImage';
import EditableButton from 'components/EditableComponents/EditableButton';
import EditableBulletList from 'components/EditableComponents/EditableBulletList';

// asset
import { ReactComponent as Vector } from './assets/Vector.svg';
import { ReactComponent as LinkIcon } from './assets/linkIcon.svg';

// style
import {
	ButtonP,
	CardPrice,
	InfoPrice,
	MediaGrid,
	SecondaCard,
	TextP,
	TitleB,
	TitleBox,
	TitleCard,
	TotalPage,
	TextCheck,
	FirstTwo,
	CardContent,
	TextBulllet,
	BlockText,
	BlockImg,
	BlockRow,
	BlockGroup,
	AddBlockButton,
} from './style/PublicProductPageDesktop.style';
import { Product } from 'types/Product';
import { putProduct } from 'services/product.services';
import NewElementModal from 'components/NewElementModal/NewElementModal.component';
import { ThematicBroadcasts } from 'pages/UsersPage/UsersTable/Products/style/Products.component.style';

const PublicProductPageDesktop: FC<{ productData: any; updateProductData: (n: number) => Promise<void>; videoOnly?: boolean}> = ({ productData, updateProductData, videoOnly = false}) => {
	const [product, setProduct] = useState<any>(productData);
	const [isAddNewElementModalOpen, setIsAddNewElementModalOpen] = useState<boolean>(false);

	const { name, salesData, id } = product;
	const bulletList = salesData?.bulletList;
	const headLine = salesData?.headLine;
	const importantPhrase = salesData?.importantPhrase ? salesData.importantPhrase : "IMPORTANT PHRASE";
	const purchaseButtonText = salesData?.purchaseButtonText;
	const salesPage = salesData?.salesPage;
	const webpageId = salesPage?.id;
	const blocks = salesPage?.blocks;
	const videoSale = salesData?.videoSale;
	const videoUrl = videoSale?.url;
	const videoCover = salesData?.videoCover;
	const videoCoverUrl = videoCover?.url;

	
	const localMedia = {
		src: videoUrl ?? 'https://www.viveredipoker.it/Libreria/Creare/Creare_Soldi_Bonus_Quanto_Guadagno.mp4',
		type: 'video/mp4',
	};
	
	const addNewElementHandler = () => setIsAddNewElementModalOpen((prevState) => !prevState);
	const closeNewElementModal = () => setIsAddNewElementModalOpen(false);
	
	const refreshProduct = async () => {
		await updateProductData(id);
	};
	
	const createNewSalesPage = async () => {
		try {
			// const response = await createWebPageForAProduct(name, id, salesData !== undefined);
			await createWebPageForAProduct(name, id, salesData !== undefined);
			await refreshProduct();
			
			// return response;
		} catch (error: any) {
			console.warn('ERROR:', error);
		}
	};
	
	const elementUpdate = async (elementId: number, editedData: string | undefined, newFileUrl: string | undefined) => {
		try {
			const updatedElement = await updateElement(elementId, editedData, newFileUrl);
			
			if (updateProductData) await updateProductData(id);
			
			return updatedElement;
		} catch (error: any) {
			console.warn('ERROR:', error);
		}
	};
	
	useEffect(() => {
		setProduct(productData);
	}, [productData]);
	
	const finalName =( name !== null ? name : 'TITOLO PRODOTTO');
	const finalHeadline = (headLine !== null ? headLine : 'HEADLINE');
	// const finalBulletList1 = (bulletList === null || bulletList === undefined) ? "BULLET LIST" : bulletList[0];
	// const finalBulletList2 = ((bulletList === null || bulletList === undefined) ? "BULLET LIST" : ( bulletList[1] ?? "BULLET LIST"));
	// const finalBulletList3 = ((bulletList === null || bulletList === undefined) ? "BULLET LIST" : ( bulletList[2] ?? "BULLET LIST"));
	const finalPurchaseButtonText = (purchaseButtonText !== null ? purchaseButtonText : '');
	const isBulletCardVisible = (headLine !== null || bulletList !== null);
	const isDescriptionCardVisible = (blocks !== null || purchaseButtonText !== null);
	
	const url = window.location.origin;
	const online = !url.includes('localhost');
	const test = url.includes('ldl-test') || !online;
	
	const salesPageBaseUrl = test ? config.testUrl : config.productionUrl;
	const newBlockPosition = (((salesPage !== undefined) && (blocks.length > 0)) ? ((blocks[blocks.length - 1]?.pos + 1) ?? 0) : 0);
	console.log("NEW BLOCK POS:", newBlockPosition);
	
	if (salesPage === undefined)
		return (
			<TotalPage>
				Questo prodotto non ha una pagina di vendita
				<AddBlockButton onClick={createNewSalesPage}>Crea una nuova pagina di Vendita</AddBlockButton>
			</TotalPage>
		);

	return (
		<>
			<TotalPage>
				<TitleBox>
					{/* <EditableTextArea 
                        textAreaId="1"
                        editableTextArea={finalName}
                        update={elementUpdate}
                    /> */}

					<TitleB>{finalName}</TitleB>
					<a
						target='_blank'
						rel='noreferrer'
						href={`${salesPageBaseUrl.replace('backoffice.', '')}app/public/product${product?.type === 'THEMATIC_BROADCAST' && product?.productThematicCall.type === 'ESPERIENZA' ? '-esperienza' : product?.type === 'THEMATIC_BROADCAST' && product?.productThematicCall.type !== 'ESPERIENZA' ? '-diretta-tematica' : ''}/${product.id}`}
					>
						<LinkIcon />
					</a>
				</TitleBox>

				<FirstTwo>
					<MediaGrid>
						<EditableVideo
							media={localMedia}
							poster={videoCoverUrl}
							productId={id}
							isRequired
							refreshProduct={refreshProduct}
							videoUrl={product?.salesData?.videoSale?.url ? product?.salesData?.videoSale?.url : undefined}
							pictureUrl={product?.salesData?.videoCover?.url ? product?.salesData?.videoCover?.url : undefined}
							spreakerBrandCode={product?.type === 'THEMATIC_BROADCAST' && product?.productThematicCall.type === 'ESPERIENZA' ? '_Ven_Esperienze' : product?.type === 'THEMATIC_BROADCAST' && product?.productThematicCall.type !== 'ESPERIENZA' ? '_Ven_Aule' : '_Ven_Corsi_Servizi'}
						/>
					</MediaGrid>

					{isBulletCardVisible && !videoOnly && (
						<CardPrice>
							<CardContent>
								<InfoPrice>
									<TitleCard>
										<EditableTextArea
											editableTextArea={finalHeadline}
											update={elementUpdate}
											productId={id}
											isRequired
											refreshProduct={refreshProduct}
										/>
									</TitleCard>

									<EditableTextArea
										editableTextArea={importantPhrase}
										update={elementUpdate}
										productId={id}
										isRequired
										refreshProduct={refreshProduct}
										typeOfTextToUpload='importantPhrase'
									/>

									<EditableBulletList
										productId={id}
										bulletList={bulletList}
										update={elementUpdate}
										isRequired
										refreshProduct={refreshProduct}
									/>

									{/* <TextBulllet>
                                        <Vector/>

                                        <EditableTextArea 
                                            blockId={1}
                                            textAreaId={1}
                                            editableTextArea={finalBulletList1}
                                            update={elementUpdate}
                                        />
                                    </TextBulllet>

                                    <TextBulllet>
                                        <Vector/>

                                        <EditableTextArea 
                                            blockId={1}
                                            textAreaId={1}
                                            editableTextArea={finalBulletList2}
                                            update={elementUpdate}
                                        />
                                    </TextBulllet>

                                    <TextBulllet>
                                        <Vector/>
                                        
                                        <EditableTextArea 
                                            blockId={1}
                                            textAreaId={1}
                                            editableTextArea={finalBulletList3}
                                            update={elementUpdate}
                                        />
                                    </TextBulllet> */}
								</InfoPrice>

								<EditableButton
									blockId={1}
									buttonElementId={1}
									update={elementUpdate}
									buttonText={`${finalPurchaseButtonText ?? 'PURCHASE BUTTON TEXT'}`}
									isRequired
									productId={id}
									refreshProduct={refreshProduct}
								/>
							</CardContent>
						</CardPrice>
					)}
				</FirstTwo>

				{isDescriptionCardVisible && !videoOnly && (
					<SecondaCard>
						<InfoPrice>
							{salesPage !== null && salesPage !== undefined && (
								<TextCheck>
									{blocks.map((block: any, index: number) => {
										if (block.elements.length === 0) return <></>;

										if (block.layout === 'SINGLE') {
											if (block.elements[0].type === 'TEXT') {
												return (
													<EditableTextArea
														key={'BTA' + index}
														editableTextArea={block.elements[0].data}
														textAreaId={block.elements[0].id}
														blockId={block.id}
														update={elementUpdate}
														refreshProduct={refreshProduct}
														typeOfLayout={block.layout}
													/>
												);
											}
											if (block.elements[0].type === 'VIDEO')
												return (
													<EditableVideo
														key={'MP' + index}
														media={{ src: block.elements[0].file.url, type: 'VIDEO/MP4' }}
														webpageId={webpageId}
														videoElementId={block.elements[0].id}
														update={elementUpdate}
														refreshProduct={refreshProduct}
														typeOfLayout={block.layout}
														spreakerBrandCode={product?.type === 'THEMATIC_BROADCAST' && product?.productThematicCall.type === 'ESPERIENZA' ? '_Ven_Esperienze' : product?.type === 'THEMATIC_BROADCAST' && product?.productThematicCall.type !== 'ESPERIENZA' ? '_Ven_Aule' : '_Ven_Corsi_Servizi'}
													/>
												);
											if (block.elements[0].type === 'IMAGE')
												return (
													<EditableImage
														key={'I' + index}
														imageUrl={block.elements[0].file.url}
														blockId={block.id}
														webpageId={webpageId}
														imageElementId={block.elements[0].id}
														refreshProduct={refreshProduct}
														typeOfLayout={block.layout}
													/>
												);
											if (block.elements[0].type === 'VSPACE') {
												for (let i = 0; i <= +block.elements[0].data; i++) {
													return <br key={'BR' + index + i} />;
												}
											}
										}

										if (block.layout === 'ROW') {
											return (
												<EditableRow
													key={'R' + index}
													rowId={block.id}
													update={elementUpdate}
													newElementPos={block.elements[block.elements.length - 1].pos + 1}
													refreshProduct={refreshProduct}
													webpageId={webpageId}
												>
													{block.elements.map((element: any, indexR: number) => {
														if (element.type === 'TEXT') {
															return (
																<EditableTextArea
																	key={'BTAR' + indexR}
																	editableTextArea={element.data}
																	textAreaId={element.id}
																	blockId={block.id}
																	update={elementUpdate}
																	refreshProduct={refreshProduct}
																	typeOfLayout={block.layout}
																	numberOfElements={block.elements.length}
																/>
															);
														}
														if (element.type === 'VIDEO')
															return (
																<EditableVideo
																	key={'MPR' + indexR}
																	media={{ src: element.file.url, type: 'VIDEO/MP4' }}
																	blockId={block.id}
																	webpageId={webpageId}
																	videoElementId={element.id}
																	update={elementUpdate}
																	refreshProduct={refreshProduct}
																	typeOfLayout={block.layout}
																	numberOfElements={block.elements.length}
																	spreakerBrandCode={product?.type === 'THEMATIC_BROADCAST' && product?.productThematicCall.type === 'ESPERIENZA' ? '_Ven_Esperienze' : product?.type === 'THEMATIC_BROADCAST' && product?.productThematicCall.type !== 'ESPERIENZA' ? '_Ven_Aule' : '_Ven_Corsi_Servizi'}
																/>
															);
														if (element.type === 'IMAGE')
															return (
																<EditableImage
																	key={'IR' + indexR}
																	imageUrl={element.file.url}
																	blockId={block.id}
																	webpageId={webpageId}
																	imageElementId={element.id}
																	refreshProduct={refreshProduct}
																	typeOfLayout={block.layout}
																	numberOfElements={block.elements.length}
																/>
															);
														if (element.type === 'VSPACE') {
															for (let i = 0; i <= +element.data; i++) {
																return <br key={'BRR' + indexR + i} />;
															}
														}
													})}
												</EditableRow>
											);
										}

										if (block.layout === 'GROUP') {
											return (
												<EditableGroup
													key={'G' + index}
													groupId={block.id}
													update={elementUpdate}
													newElementPos={block.elements[block.elements.length - 1].pos + 1}
													refreshProduct={refreshProduct}
													webpageId={webpageId}
												>
													{block.elements.map((element: any, indexG: number) => {
														if (element.type === 'TEXT') {
															return (
																<EditableTextArea
																	key={'BTAG' + indexG}
																	editableTextArea={element.data}
																	textAreaId={element.id}
																	blockId={block.id}
																	update={elementUpdate}
																	refreshProduct={refreshProduct}
																	typeOfLayout={block.layout}
																	numberOfElements={block.elements.length}
																/>
															);
														}
														if (element.type === 'VIDEO')
															return (
																<EditableVideo
																	key={'MPG' + indexG}
																	media={{ src: element.file.url, type: 'VIDEO/MP4' }}
																	blockId={block.id}
																	webpageId={webpageId}
																	videoElementId={element.id}
																	update={elementUpdate}
																	refreshProduct={refreshProduct}
																	typeOfLayout={block.layout}
																	numberOfElements={block.elements.length}
																	spreakerBrandCode={product?.type === 'THEMATIC_BROADCAST' && product?.productThematicCall.type === 'ESPERIENZA' ? '_Ven_Esperienze' : product?.type === 'THEMATIC_BROADCAST' && product?.productThematicCall.type !== 'ESPERIENZA' ? '_Ven_Aule' : '_Ven_Corsi_Servizi'}
																/>
															);
														if (element.type === 'IMAGE')
															return (
																<EditableImage
																	key={'IG' + indexG}
																	imageUrl={element.file.url}
																	imageElementId={element.id}
																	blockId={block.id}
																	webpageId={webpageId}
																	refreshProduct={refreshProduct}
																	typeOfLayout={block.layout}
																	numberOfElements={block.elements.length}
																/>
															);
														if (element.type === 'VSPACE') {
															for (let i = 0; i <= +element.data; i++) {
																return <br key={'BRG' + indexG + i} />;
															}
														}
													})}
												</EditableGroup>
											);
										}
									})}
								</TextCheck>
							)}
						</InfoPrice>

						<EditableButton
							blockId={1}
							buttonElementId={1}
							update={elementUpdate}
							buttonText={`${finalPurchaseButtonText ?? 'PURCHASE BUTTON TEXT'}`}
							isRequired
							productId={id}
							refreshProduct={refreshProduct}
						/>
					</SecondaCard>
				)}

				{ !videoOnly && <AddBlockButton onClick={addNewElementHandler}>Aggiungi un nuovo elemento</AddBlockButton>}
			</TotalPage>

			{isAddNewElementModalOpen && (
				<NewElementModal
					onCloseButtonModal={closeNewElementModal}
					newBlockPosition={newBlockPosition}
					webpageId={webpageId}
					updateData={refreshProduct}
				/>
			)}
		</>
	);
};

export default PublicProductPageDesktop;
