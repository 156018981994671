//QUESTO E' quello CORRETTO PER AGGIUNTA PRODOTTO

import { FC, useEffect, useRef, useState } from 'react';

//hooks
import useAppDispatch from 'hooks/useAppDispatch';
import { useNavigate } from 'react-router-dom';
import { getBrand } from 'configs/brandMap/brandMap.helper';

//redux
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';
import { addSnackbar } from 'redux/actions/actions-snackbar';
import { addProductToState } from 'redux/actions/actions-global';

//types
import { ProductsTableProps } from './ProductsTable.component.d';
import { ProductsTableColumn } from './ProductsTable.component.d';
import { Product } from 'types/Product';
import { LdLFile } from 'types/LdLFile';
import { ProductThematicBroadcast } from 'types/ProductThematicBroadcast';

//utils
import config from 'configs/config';
import { uploadRecordedVideo } from 'pages/ProductDetailPage/ProductTab/ThematicBroadcastLinksTab/services/ThematicBroadcastLinksTab.services';
import { addProduct } from '../services/ProductsPage.services';
import { getProductFromId } from 'services/product.services';
import { removeThematicBroadcastDiscountPrices } from 'pages/ProductDetailPage/PercentagesTab/services/PercentagesTab.services';
import { 
	createWebPageForAProduct, 
	createNewBlock, 
	createNewElement, 
	updateImageIntoElements, 
	updateVideoIntoElements, 
	updateVideoOnSalesData, 
	updateVideoPosterOnSalesData, 
	uploadImageIntoElements } from 'pages/PublicProductPages/services/PublicProductPage.services';

//assets
import emptyPicIconSVG from './assets/emptyPicIcon.svg';
import { ReactComponent as LinkIcon } from './assets/linkIcon.svg';
import DefaultProductPic from './assets/defaultProductPic.png';

//components
import Visible from './Visible';
import RowButtons from './RowButtons';
import Price from './Price';
import EditableInput from 'components/EditableComponents/EditableInput/EditableInput.component';


//style
import { 
	Table,
	TableBody, 
	TableBodyCell, 
	TableBodyRow, 
	TableHead, 
	TableHeadCell, 
	TableHeadRow, 
	SellImage, 
	ProductName, 
	Subscriptions, 
	RedBackText, 
	GreenBackText,
	ThematicBroadcastDoneLink,
	AttachNewFileButton,
	AttachmentNewFile,
	LabelFileButton,
} from './style/ProductsTable.style';
import useHasRole from 'hooks/useHasRole';
import { AxiosProgressEvent } from 'axios';



const url = window.location.origin;
const online = !url.includes('localhost');
const test = url.includes('ldl-test') || !online;

const salesPageBaseUrl = test ? config.testUrl.replace('backoffice.','') : config.productionUrl.replace('backoffice.','');

const ProductsTable: FC<ProductsTableProps> = ({ data, productType, productCategory, putProduct }) => {
	const [columns, setColumns] = useState<ProductsTableColumn[]>([]);
	const [editLinkRowId, setEditLinkRowId] = useState<number | null>(null);
	const [tableData, setTableData] = useState<Product[]>([]);
	const [uploadProgress, setUploadProgress] = useState(0);
	const [isUploading, setIsUploading] = useState(false);

	const attachmentNewFileInput = useRef<HTMLInputElement>(null);

	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const brand = getBrand();

	const isAdmin = useHasRole('ADMIN');
	const isSuperAdmin = useHasRole('SUPERADMIN');
	const isMiniAdmin = useHasRole('MINIADMIN');
	const hasUploadVideoRole = useHasRole('UPLOAD_VIDEO');
	const isRegistaLDL = useHasRole('REGISTA-LDL');
	const isRegistaMB = useHasRole('REGISTA-MB')
	const isRegistaTCR = useHasRole('REGISTA-TCR');
	const isEditorLDL = useHasRole('EDITOR-LDL');
	const isEditorMB = useHasRole('EDITOR-MB');
	const isEditorTCR = useHasRole('EDITOR-TCR');
	const hasAdminRole = isAdmin || isSuperAdmin;
	const isRegista = isRegistaLDL || isRegistaMB || isRegistaTCR;
	const isEditor = isEditorLDL || isEditorMB || isEditorTCR;

	useEffect(()=> {
		const baseColumns = [
			{ label: '', width: '3%' },
			{ label: 'ID', width: '2%' },
			{ label: productType !== 'THEMATIC_BROADCAST' ? 'IMMAGINE' : 'DATA', width: '5%' },
			{ label: 'NOME', width: '12%' },
			{ label: productType === 'THEMATIC_BROADCAST' ? productCategory === 'ESPERIENZA' ? 'PREZZO' : 'PREZZO CATALOGO' : 'PREZZO', width: '5%' },
			{ label: productType === 'SERVICE' ? 'ABBONATI' :  'COPIE', width: '3%' },
			// { label: 'METODI PAGAMENTO', width: '5%' },
			// { label: 'PESO', width: '5%' },
			{ label: 'VISIBILE', width: '3%' },
			// { label: 'BRAND', width: '5%' },
			// { label: 'PRODOTTI', width: '4%' },
			// {label:'AUTOSTRADA',width: '1%'},
			// {label:'LEZ. AP',width: '1%'},
			// { label: 'STATS', width: '5%' },
			// { label: 'NOTE', width: '2%' },
		];
		const addAuthorsColumn = (columns: ProductsTableColumn[]): ProductsTableColumn[] => {
			const newColumn: ProductsTableColumn = { label: 'AUTORI', width: '5%' };
			const alreadyInserted = columns.findIndex(col => col.label === 'AUTORI');
			const indexToInsert = columns.findIndex(col => productType === 'THEMATIC_BROADCAST' && col.label === 'VISIBILE');
			if ((indexToInsert !== -1) && (alreadyInserted === -1)) {
				columns.splice(indexToInsert, 0, newColumn);
			}
			return columns;
		};
		const updatedColumns = addAuthorsColumn(baseColumns);
		setColumns(updatedColumns);
	}, [productType]);

	// Sposta il controllo dopo gli hooks
	if (!data || data.length === 0) {
		return <div>Nessun prodotto disponibile</div>;
	}

	const MEDIA_ALLOWED_FILE_TYPES = ['mp4'];//, 'mp3', 'm4a'];
	const spreakerBrandCode = productType === 'THEMATIC_BROADCAST' && productCategory === 'ESPERIENZA' ? '_Esperienze' : productType === 'THEMATIC_BROADCAST' && productCategory !== 'ESPERIENZA' ? '_Aule' : '_Corsi_Servizi';

	const extractFilename = (filePath: string) => {
		const segments = filePath.split('/');
		const filename = segments[segments.length - 1];
		return (filename !== '') ? filename : filePath;
	};

	const handleClickEditLink = (id: number) => {
		setEditLinkRowId(id);
	}
	  
	const handleFileButton = () => {
		if (attachmentNewFileInput.current) {
			attachmentNewFileInput.current.click();
		}
	}

	const handleSingleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
		if (editLinkRowId === null) return;
		const productRegistrationLink = data.find(row => row.id === editLinkRowId)?.productThematicCall.registrationLink;
		const file = event.target.files?.[0];
		if (file) {
			if (!MEDIA_ALLOWED_FILE_TYPES.includes(file.name.split('.').pop()!)) {
				const snackbarOptions: SnackbarOptions = {
					message: 'Tipo di file non consentito',
					type: 'error',
				};
				dispatch(addSnackbar(snackbarOptions));
				return;
			}
			setIsUploading(true);
			const fileName = extractFilename(file.name);
			var newFormData = new FormData();
			newFormData.append('file', file, fileName);
			try {
				const uploaded = await uploadRecordedVideo(
					editLinkRowId, 
					newFormData, 
					undefined, 
					spreakerBrandCode,
					(progressEvent: AxiosProgressEvent) => {
						if (progressEvent.total) { // Verifica che 'total' sia definito
							const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
							setUploadProgress(percentCompleted);
						}
					}
				);
				const oldRow = data.find(row => row.id === editLinkRowId);
				if (!oldRow) return;
				var updatedThematicCall: ProductThematicBroadcast = {
					...oldRow.productThematicCall,
					registrationLink: uploaded as LdLFile,
				};
				if ((oldRow.productThematicCall.type !== 'ESPERIENZA') && (!productRegistrationLink)) {
					updatedThematicCall.status = 'PUBLISHED';
				}
				const updatedProduct: Partial<Product> = {
					id: editLinkRowId,
					productThematicCall: updatedThematicCall as ProductThematicBroadcast,
				};
				await putProduct(updatedProduct);
				setEditLinkRowId(null);
				const snackbarOptions: SnackbarOptions = {
					message: 'File caricato con successo',
					type: 'success',
				};
				dispatch(addSnackbar(snackbarOptions));
				setTimeout(() => {
					setUploadProgress(0);
				}, 1000);
			} catch (error: any) {
				const snackbarOptions: SnackbarOptions = {
					message: 'Errore durante il caricamento: '+error?.message,
					type: 'error',
				};
				dispatch(addSnackbar(snackbarOptions));
				setUploadProgress(-1);
			} finally {
				setIsUploading(false);
			}
		}
	};

	const updateProductInfo = async (id: string, content: string) => {
		if (id !== 'registration') return;
		if (editLinkRowId === null) return;
		const productRegistrationLink = data.find(row => row.id === editLinkRowId)?.productThematicCall.registrationLink;
		try {
			var uploadedVideo = await uploadRecordedVideo(editLinkRowId, undefined, content);
			const oldRow = data.find(row => row.id === editLinkRowId);
			if (!oldRow) return;
			var updatedThematicCall: ProductThematicBroadcast = {
				...oldRow.productThematicCall,
				registrationLink: uploadedVideo as LdLFile,
			};
			if ((oldRow.productThematicCall.type !== 'ESPERIENZA') && (!productRegistrationLink)) {
				updatedThematicCall.status = 'PUBLISHED';
			}
			const updatedProduct: Partial<Product> = {
				id: editLinkRowId,
				productThematicCall: updatedThematicCall as ProductThematicBroadcast,
			};
			await putProduct(updatedProduct);
			setEditLinkRowId(null);
		} catch (error) {
			const snackbarData: SnackbarOptions = {};
			snackbarData.type = 'error';
			snackbarData.message = `C'è stato qualche problema nel salvataggio del video!`;
			dispatch(addSnackbar(snackbarData));
			throw error;
		}
	}

	const getProxiedUrl = (url: string) => {
		if (url.startsWith('http')) {
			// Se siamo in sviluppo, usa un URL relativo
			return url.replace(/^https?:\/\/[^/]+/, '');
		}
		return url;
	};

	const fetchImageAsFile = async (imageUrl: string, fileName: string): Promise<File> => {
		try {
			const response = await fetch(imageUrl, {
				method: 'GET',  
				cache: 'no-cache',
				headers: {
					'Accept': 'image/png,image/jpeg,image/*',
				},
			});

			// Log della risposta per debug
			console.log('Response headers:', Array.from(response.headers.entries()));
			console.log('Response status:', response.status);
			
			const blob = await response.blob();
			console.log('Blob size:', blob.size);
			console.log('Blob type:', blob.type);


			const file = new File([blob], fileName, { 
				type: blob.type || 'image/png',
				lastModified: new Date().getTime()
			});

			return file;
		} catch (error) {
			console.error("Errore nel recupero dell'immagine:", error);
			
			// Se il fetch fallisce, prova con l'approccio Image
			return new Promise((resolve, reject) => {
				const img = new Image();
				img.crossOrigin = 'anonymous';
				
				img.onload = () => {
					const canvas = document.createElement('canvas');
					canvas.width = img.width;
					canvas.height = img.height;
					
					const ctx = canvas.getContext('2d');
					if (!ctx) {
						reject(new Error('Failed to get canvas context'));
						return;
					}
					
					ctx.drawImage(img, 0, 0);
					
					canvas.toBlob((blob) => {
						if (!blob) {
							reject(new Error('Failed to create blob'));
							return;
						}
						
						const file = new File([blob], fileName, {
							type: 'image/png',
							lastModified: new Date().getTime()
						});
						resolve(file);
					}, 'image/png');
				};
				
				img.onerror = () => {
					reject(new Error('Failed to load image'));
				};
				
				img.src = imageUrl;
			});
		}
	};
	
	const updateVideoPosterFromUrl = async (productId: number, imageUrl: string) => {
		try {
			const fileName = imageUrl.substring(imageUrl.lastIndexOf('/') + 1);
			const imageFile: File = await fetchImageAsFile(imageUrl, fileName);
			return await updateVideoPosterOnSalesData(productId, imageFile);
		} catch (error: any) {
			throw error;
		}
	};

	const duplicateProduct = async (productId: number) => {
		if (!brand) return;
		const product = await getProductFromId(productId, brand.code);
		if (!product) return;
		if (product.type !== 'THEMATIC_BROADCAST') return; // at the moment we can duplicate ONLY thematic broadcasts
		/* eslint-disable no-restricted-globals */
		if (!confirm("Vuoi veramente duplicare il prodotto '"+product.name+"'?")) return;
		const newProduct: Partial<Product> = { name: product.name + " (copia)", visible: false, duplicationIncentiveActive: false };
		newProduct.productThematicCall = { price: 0, discountProtagonistPrice: 0, discountSpectatorPrice: 0, onShop: false, protagonistPrice: 0, spectatorPrice: 0, vip: false, type: product.productThematicCall.type,} as ProductThematicBroadcast;
		try {
			var response = await addProduct(newProduct);
			if ((product?.salesData?.videoCover) || (product?.salesData?.videoSale)) {
				await createWebPageForAProduct(response.name, response.id, false);
				if (product.salesData?.videoSale) {
					await updateVideoOnSalesData(response.id, product.salesData.videoSale.url);
				}
				if (product.salesData?.videoCover) {
					await updateVideoPosterFromUrl(response.id, product.salesData.videoCover.url);
				}
				response = await getProductFromId(response.id, brand.code);
			}
			response.authorsNames = product.authorsNames;
			response.paymentTypes = product.paymentTypes;
			response.productPools = product.productPools;
			response.free = product?.free ? product.free : false;
			response.salesData.delivery = product.salesData.delivery;
			response.salesData.weight = product.salesData.weight;
			response.subCategories = product?.subCategories ? product.subCategories : [];
			response.requisites = product?.requisites ? product.requisites : [];
			
			if (response.productPools.length > 0) {
				const updatedPools = response.productPools.map((poolEntry: any) => {
					if (poolEntry.id.productId === productId) {
						return {
							...poolEntry,
							id: {
								...poolEntry.id,
								productId: response.id
							}
						};
					}
					return poolEntry;
				});
				response.productPools = updatedPools;
			}

			if (response.requisites.length > 0) {
				const updatedRequisites = response.requisites.map((requisite: any) => {
					if (requisite.id.productId === productId) {
						return {
							...requisite,
							id: {
								...requisite.id,
								productId: response.id
							}
						};
					}
					return requisite;
				});
				response.requisites = updatedRequisites;
			}

			if ((product.productThematicCall.type === 'ESPERIENZA') && (product.productThematicCall?.registrationLink?.url)) {
				var uploadedVideo = await uploadRecordedVideo(response.id, undefined, product.productThematicCall.registrationLink.url);
				response.productThematicCall.registrationLink = uploadedVideo as LdLFile;
			}

			response.productThematicCall = { 
				...response.productThematicCall, 
				description: product.productThematicCall.description,
				discountProtagonistPrice: product.productThematicCall.discountProtagonistPrice,
				discountSpectatorPrice: product.productThematicCall.discountSpectatorPrice,
				facebookGroupId: product.productThematicCall.facebookGroupId,
				maxProtagonist: product.productThematicCall.maxProtagonist,
				maxSpectator: product.productThematicCall.maxSpectator,
				price: product.productThematicCall.price,
				protagonistPrice: product.productThematicCall.protagonistPrice,
				spectatorPrice: product.productThematicCall.spectatorPrice,
				vip: product.productThematicCall.vip,
				onShop: product.productThematicCall.onShop,
			} as ProductThematicBroadcast;
			await putProduct(response);

			await Promise.all([
				dispatch(addProductToState(response)),
				new Promise(resolve => setTimeout(resolve, 100)) // Piccolo delay per sicurezza
			]);

			if ((product.productThematicCall.discountProtagonistPrice === null) &&(product.productThematicCall.discountSpectatorPrice === null)) {
				await removeThematicBroadcastDiscountPrices(response.id);
			}
			navigate('/reservedArea/products/' + response.id);
		} catch (error: any) {
			const snackbarData: SnackbarOptions = {};
			snackbarData.type = 'error';
			snackbarData.message = `C'è stato qualche problema nel duplicare il prodotto!`;
			dispatch(addSnackbar(snackbarData));
			throw error;
		}
	}

	return (
		<>
			<Table>
				<TableHead>
					<TableHeadRow>
						{columns.map((c) => (
							<TableHeadCell
								key={c.label}
								width={c.width}
								// onClick={() => handleSort(key)}
							>
								{c.label.toUpperCase()}
								{/* {sortColumn === c && <span>{sortDirection === 'asc' ? '⬆️' : '⬇️'}</span>} */}
							</TableHeadCell>
						))}
					</TableHeadRow>
				</TableHead>
				<TableBody>
					{data.map((row, index) => {
						// Verifichiamo che row e le sue proprietà necessarie esistano
						if (!row) return null;
						
						return (
							<TableBodyRow key={index}>
								<TableBodyCell
									key={index + '_buttons'}
									id={index + '_buttons'}
								>
									<RowButtons 
										productId={row.id} 
										duplicateProduct = {productType === 'THEMATIC_BROADCAST' && (isSuperAdmin || isRegista || isEditor) ? duplicateProduct : undefined}
									/>
								</TableBodyCell>
								<TableBodyCell
									key={index + '_id'}
									id={index + '_id'}
								>
									{row.id}
								</TableBodyCell>

								{productType !== 'THEMATIC_BROADCAST' && <TableBodyCell
									key={index + '_image'}
									id={index + '_image'}
								>
									<SellImage
										alt='-'
										src={row.sellImage?.url ? row.sellImage.url : DefaultProductPic}
									/>
								</TableBodyCell>
								}

								{productType === 'THEMATIC_BROADCAST' && <TableBodyCell
									key={index + '_image'}
									id={index + '_image'}
								>
									{row?.productThematicCall?.liveDate && (
										<>
										{(new Date(row.productThematicCall.liveDate+"Z")).getDate().toString().padStart(2, '0')}/
										{((new Date(row.productThematicCall.liveDate+"Z")).getMonth() + 1).toString().padStart(2, '0')}/
										{(new Date(row.productThematicCall.liveDate+"Z")).getFullYear().toString()}&nbsp;
										{(new Date(row.productThematicCall.liveDate+"Z")).getHours().toString().padStart(2, '0')}:
										{(new Date(row.productThematicCall.liveDate+"Z")).getMinutes().toString().padStart(2, '0')}<br/>
										{
											row.productThematicCall.status === "CREATED" ? "Creata" :
											row.productThematicCall.status === "PUBLISHED" ? (
												<a href={row.productThematicCall.registrationLink?.url} target='_blank' style={{ color: 'inherit', textDecoration: 'none', backgroundColor: 'transparent' }}>
													<GreenBackText>
														{row.productThematicCall.type === "ESPERIENZA" ? 
															(new Date(row.productThematicCall.liveDate+"Z") <= (new Date())) ? 
																(new Date(row.productThematicCall.saleCollectionDate+"Z") >= (new Date())) ? "Iniziata" : "Finita" : "Pubblicata" :
															"Link caricato"
														}
													</GreenBackText>
												</a>) :
											row.productThematicCall.status === "SCHEDULED" ? 
												row.productThematicCall.type === "ESPERIENZA" ? 
													(new Date(row.productThematicCall.liveDate+"Z") <= (new Date())) ? 
													(new Date(row.productThematicCall.saleCollectionDate+"Z") >= (new Date())) ? "Iniziata" : "Finita" : "Pubblicata" :		
											(new Date(row.productThematicCall.liveDate) >= (new Date())) ? "Pubblicata" : 
											<ThematicBroadcastDoneLink>
												{editLinkRowId === row.id && (isSuperAdmin || hasUploadVideoRole) ? <>
													{isSuperAdmin && <EditableInput
														inputId={'registration'}
														editableInput={''}
														placeholder={"Inserisci link FTP"}
														update={updateProductInfo}
														pencil={false}
														mandatoryToBeCompleted={['.mp4', '.mp3', '.m4a']}
													/>}
													<LabelFileButton>{MEDIA_ALLOWED_FILE_TYPES ? "Tipi di file consentiti: " + MEDIA_ALLOWED_FILE_TYPES.join(', ') : ""}</LabelFileButton>
													<AttachNewFileButton 
														disabled={isUploading} 
														onClick={handleFileButton}
													>
													{isUploading ? uploadProgress < 100 ? `Caricamento... ${uploadProgress}%` : 'Attendere...' : uploadProgress === -1 ? 'Errore!' : 'Carica nuovo file'}
													</AttachNewFileButton>
												</>
												: <>
													<RedBackText onClick={() => handleClickEditLink(row.id)}>Fatta</RedBackText>
												</>
												}
											</ThematicBroadcastDoneLink> :
											""
										}
										</>)
									}
								</TableBodyCell>
								}

								<TableBodyCell
									key={index + '_name'}
									id={index + '_name'}
								>
									<ProductName>
										<a
											target='_blank'
											rel='noreferrer'
											href={`${salesPageBaseUrl}${productType === 'THEMATIC_BROADCAST' ? productCategory === 'ESPERIENZA' ? 'app/public/product-esperienza/' : 'app/public/product-diretta-tematica/' : 'app/public/product/'}${row.id}`}
										>
											<LinkIcon />
										</a>
										{row.name}
									</ProductName>
								</TableBodyCell>
								<TableBodyCell
									key={index + '_price'}
									id={index + '_price'}
								>
									<Price
										product={row}
										putProduct={putProduct}
									/>
								</TableBodyCell>
								<TableBodyCell
									key={index + '_subscriptions'}
									id={index + '_subscriptions'}
								>
									<Subscriptions>{row.subscriptions?.toLocaleString() || '0'}</Subscriptions>
								</TableBodyCell>
								{/* <TableBodyCell
									key={index + '_paymentMethods'}
									id={index + '_paymentMethods'}
								></TableBodyCell> */}
								{/* <TableBodyCell
									key={index + '_weight'}
									id={index + '_weigth'}
								>
									{row.salesData?.weight}
								</TableBodyCell> */}
								{productType === 'THEMATIC_BROADCAST' && <TableBodyCell
									key={index + '_authors'}
									id={index + '_authors'}
								>
									{row.authorsNames && (
										<>
										{row.authorsNames?.map((author, index) => <>{author}<br/></>)}
										</>)
									}
								</TableBodyCell>
								}
								<TableBodyCell
									key={index + '_visible'}
									id={index + '_visible'}
								>
									<Visible
										productId={row.id}
										visible={row.visible}
										putProduct={putProduct}
									/>
								</TableBodyCell>
								{/* <TableBodyCell
									key={index + '_brands'}
									id={index + '_brands'}
								>
									{row.brands.map((b, i) => (
										<div key={i}>{b.code}</div>
									))}
								</TableBodyCell> */}
							</TableBodyRow>
						);
					})}
				</TableBody>
			</Table>
			<AttachmentNewFile
				ref={attachmentNewFileInput}
				onChange={handleSingleFileChange}
				type="file"
				accept={MEDIA_ALLOWED_FILE_TYPES.map(ext => `.${ext}`).join(',')}
			/>
		</>
	);
};

export default ProductsTable;

