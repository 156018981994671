import colors from 'style/palette/palette';
import styled, { css } from 'styled-components';


export const CardContent = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
    gap: 30px;

    width: 1000px;

    margin: 0;
    padding: 30px;

    border: 1px solid ${colors.primaryColor};
    border-radius: 15px;

    background: ${colors.grey};
    color: ${colors.white};

    div {
        width: 100%;
    }
`

export const RowContent = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    gap: 30px;

    width: 100%;
`;

export const ColumnContent = styled.div`

    display: flex;
    align-items: flex-start;
    justify-content: center;

    flex-direction: column;
    gap: 30px;

    width: 100%;
`;

export const ButtonRow = styled.div`

    display: flex;
    align-items: center;
    justify-content: flex-end;

    gap: 30px;

    width: 100%;
`;

export const SaveAndCloseButton = styled.button`

    margin: 0;
    padding: 20px;

    border: 1px solid ${colors.primaryColor};
    border-radius: 5px;

    background: ${colors.blueGrey};
    color: ${colors.black087};
    font-size: 15px;
    font-weight: 500;

    cursor: pointer;
`;

export const CloseAndCancel = styled(SaveAndCloseButton)``;

export const DeleteElementButton = styled(SaveAndCloseButton)``;

export const UploadPosterButton = styled(SaveAndCloseButton)`
    margin: 0 25px;
`;

export const UrlInput = styled.input`

    width: 100%;
    margin: 0 0 10px 0;
    
    border-radius: 5px;
    border: 1px solid ${colors.white070};
    background: ${colors.lightGrey};
`;

export const UrlLabel = styled.label`
    width: 100%;
`;

export const ActualPicture = styled.div`
    margin: 0 0 30px 0;
`;

export const VideoUrlBox = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
`;

export const VideoButtonBox = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	gap: 1rem;

	> label {
		font-size: 12px;
	}
`;

export const Label = styled.label``;

export const AttachNewFileButton = styled.button`
	color: #000000;

  	max-width: 50%;

    border: none;
    border-radius: 4px;
    background-color: ${colors.lightSimpleGrey};

    cursor: pointer;
`;

export const AttachmentNewFile = styled.input.attrs({ 
    type: 'file',
  })`
    display: none;
`;

export const RemoveButton = styled.button`
    font-size: 1.1em;

    background-color: red;
    color: white;

    border: none;
    border-radius: 4px;

    cursor: pointer;
`;
