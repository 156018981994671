import { FC, useEffect, useState } from 'react';

//redux
import { enableAppMain } from 'redux/slices/slice-app-main';
import { addSnackbar } from 'redux/actions/actions-snackbar';
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';

//hooks
import useAppSelector from 'hooks/useAppSelector';
import useAppDispatch from 'hooks/useAppDispatch';
import useHasRole from 'hooks/useHasRole';
import useHasPermission from 'hooks/useHasPermission';
import useBrand from 'hooks/useBrand';

//utils
import { assignPackToAP, getSearchHuntersLessons, getSearchMyLessons, getCoaches } from './services/MyLessonsPage.services';
import { getSearchAPLessons, assignLessonToAP } from 'pages/APLessonsPage/services/APLessonsPage.services';
import { getBrand } from 'configs/brandMap/brandMap.helper';

//types
import { TitleBreadCrumbSettings } from 'components/DashboardTitleSection/DashboardTitleSection.component.d';
import { TabSelectorSettings, TabSelectorItem } from 'components/TabSelector/TabSelector.component.d';
import { APLessonsNewTableItem } from 'pages/APLessonsPage/APLessonsNewTable/APLessonsNewTable.component.d';
import { MyLessonsHuntersTableItem } from './MyLessonsHuntersTable/MyLessonsHuntersTable.component.d';
import { MyLessonsOpenTableItem } from './MyLessonsOpenTable/MyLessonsOpenTable.component.d';
import { MyLessonsClosedTableItem } from './MyLessonsClosedTable/MyLessonsClosedTable.component.d';
import { User } from 'types/User.d';

//components
import DashboardTitleSection from 'components/DashboardTitleSection';
import TabSelector from 'components/TabSelector/TabSelector.component';
import MyLessonsHuntersTable from './MyLessonsHuntersTable/MyLessonsHuntersTable.component';
import MyLessonsOpenTable from './MyLessonsOpenTable/MyLessonsOpenTable.component';
import MyLessonsClosedTable from './MyLessonsClosedTable/MyLessonsClosedTable.component';
import APLessonsNewTable from 'pages/APLessonsPage/APLessonsNewTable/APLessonsNewTable.component';

//assets
import { ReactComponent as SearchIconSVG } from './assets/searchIcon.svg';
import { ReactComponent as ClearButtonIconSVG } from './assets/clearButtonIcon.svg';

//style
import { BaseContentCard } from 'style/wrappers/wrappers';
import { Page, SearchChannelBox, SearchInput, SearchClearButton, SearchButton, AssignBox, AssignSelect, AssignButton } from './style/MyLessonsPage.style';
import { MyLessonsOpenDetailsTableItem } from './MyLessonsOpenDetailsTable/MyLessonsOpenDetailsTable.component.d';
import MyLessonsOpenDetailsTable from './MyLessonsOpenDetailsTable';

const MyLessonsPage: FC<{}> = () => {
	const [searchInput, setSearchInput] = useState<any>('');
	const [searchNewResults, setSearchNewResults] = useState<APLessonsNewTableItem[]>([]);
	const [searchHuntersResults, setSearchHuntersResults] = useState<MyLessonsHuntersTableItem[]>([]);
	const [originalOpenResults, setOriginalOpenResults] = useState<any>([]);
	const [searchOpenResults, setSearchOpenResults] = useState<MyLessonsOpenTableItem[]>([]);
	const [searchOpenWAResults, setSearchOpenWAResults] = useState<MyLessonsOpenTableItem[]>([]);
	const [openDetailsResults, setOpenDetailsResults] = useState<MyLessonsOpenDetailsTableItem[]>([]);
	const [searchClosedResults, setSearchClosedResults] = useState<MyLessonsClosedTableItem[]>([]);
	const [searchClosedWAResults, setSearchClosedWAResults] = useState<MyLessonsClosedTableItem[]>([]);
	const [totalPages, setTotalPages] = useState<number>(0);
	const [totalElements, setTotalElements] = useState<number>(0);
	const [pageSize, setPageSize] = useState<number>(0);
    const [coaches, setCoaches] = useState<any[]>([]);
    const [coachDetails, setCoachDetails] = useState<number | null>(null);
    const [personalAssistants, setPersonalAssistants] = useState<any[]>([]);
    const [tabSelectorValue, setTabSelectorValue] = useState<string>('');
    const [assignAssistantSelect, setAssignAssistantSelect] = useState<number>(0);
    const [selectedIds, setSelectedIds] = useState<number[]>([]);

	const { id: userId, name: userName } = useAppSelector((state) => state.user);

	const isSuperAdmin = useHasRole('SUPERADMIN');
	const isAdmin = useHasRole('ADMIN');
	const isPersonalTrainer = useHasRole('PERSONAL TRAINER');
	const isCoach = useHasRole('COACH');
	const isAssistentePersonale = useHasRole('ASSISTENTE-PERSONALE');
	const isSupervisoreAutostrada = useHasRole('SUPERVISORE_AUTOSTRADA');
	const isAdminAutostrada = useHasRole('ADMIN_AUTOSTRADA');

	const canSeeOpenCalls = isPersonalTrainer || isCoach || isAssistentePersonale;
	const canSeeClosedCalls = isPersonalTrainer || isCoach || isAssistentePersonale;
	const canTakeChargeHunters = isPersonalTrainer || isCoach || isAssistentePersonale;

	const dispatch = useAppDispatch();
	const brand = useBrand();

	const clearSearchHandler = () => {
		setSearchInput('');
	};

	const searchChangeHandler = (searchValue: any) => {
		setSearchInput(searchValue.target.value);
	};

	const searchKeyHandler = (event: any) => {
		if (event.keyCode === 27) {
			clearSearchHandler();
		}
	};

	const getExpirationDate = (thispackage: any): Date | null => {
		var expirationDate = thispackage.product.productLessonPack.expirationDate;
		if (expirationDate) return expirationDate;
		if (thispackage.creationDate) {
			expirationDate = new Date(thispackage.creationDate);
		}
		const january2023 = new Date("2023-01-01");
		if (expirationDate < january2023) {
			expirationDate.setFullYear(expirationDate.getFullYear() + 1);
			return expirationDate.toISOString();
		}
		if (thispackage.productLessonPack.price === 0) {
			expirationDate.setDate(expirationDate.getDate() + 15);
			return expirationDate.toISOString();
		}
		if (thispackage.product.productLessonPack.type.name === "Lezione AP") {
			expirationDate.setDate(expirationDate.getDate() + 30);
			return expirationDate.toISOString();
		}
		if (expirationDate instanceof Date && !isNaN(expirationDate.getTime())) { 
			const utcDate = expirationDate.toUTCString();
			expirationDate = new Date(utcDate);
			expirationDate.setDate(expirationDate.getDate() + 90);
			return expirationDate.toISOString();
		}
		return null;
	}

	const getAllCoaches = async () => {
		var ncoaches = [];
		const response = await getCoaches();
		if (response) {
			ncoaches = response.filter((coach: any) => coach.deleted === false);
		}
		setCoaches(ncoaches);
	}

	// const getAllLessons = (data: any[], status: string): any[] => {
	// 	var lessons: any[] = [];
	// 	data.forEach((thispackage: any) => {
	// 		if ((thispackage.productLessonPack.lessons) && (thispackage.productLessonPack.lessons.length>0)) {
	// 			thispackage.productLessonPack.lessons.forEach((lesson: any) => {
	// 				if (lesson.status.startsWith(status)) {
	// 					lessons.push({
	// 						lesson, 
	// 						productSubscriptionId: thispackage.productLessonPack.productSubscriptionId, 
	// 						customer: thispackage.user, 
	// 						type: thispackage.product.productLessonPack.type.name, 
	// 						creationDate: thispackage.creationDate, 
	// 						expirationDate: getExpirationDate(thispackage), 
	// 						price: thispackage.productLessonPack.price,
	// 						duration: thispackage.productLessonPack.duration,
	// 						status: thispackage.productLessonPack.status,
	// 						closingNotes: thispackage.productLessonPack.closingNotes,
	// 					});
	// 				}
	// 			});
	// 		}
	// 	});
	// 	return lessons;
	// }

	// const getAllLessons = (data: any[], statuses: string[]): any[] => {
	// 	var lessons: any[] = [];
	// 	data.forEach((thispackage: any) => {
	// 	  if (thispackage.productLessonPack.lessons && thispackage.productLessonPack.lessons.length > 0) {
	// 		thispackage.productLessonPack.lessons.forEach((lesson: any) => {
	// 		  if (statuses.some((status: string) => lesson.status.startsWith(status))) {
	// 			lessons.push({
	// 			  lesson,
	// 			  productSubscriptionId: thispackage.productLessonPack.productSubscriptionId,
	// 			  customer: thispackage.user,
	// 			  type: thispackage.product.productLessonPack.type.name,
	// 			  creationDate: thispackage.creationDate,
	// 			  expirationDate: getExpirationDate(thispackage),
	// 			  price: thispackage.productLessonPack.price,
	// 			  duration: thispackage.productLessonPack.duration,
	// 			  status: thispackage.productLessonPack.status,
	// 			  closingNotes: thispackage.productLessonPack.closingNotes,
	// 			});
	// 		  }
	// 		});
	// 	  }
	// 	});
	// 	return lessons;
	//   };
	  
	// const getAllLessons = (data: any[], statuses: [string, string[]][]): any[] => {
	// 	var lessons: any[] = [];
	// 	data.forEach((thispackage: any) => {
	// 	  if (thispackage.productLessonPack.lessons && thispackage.productLessonPack.lessons.length > 0) {
	// 		thispackage.productLessonPack.lessons.forEach((lesson: any) => {
	// 		  if (statuses.some(([status, types]: [string, string[]]) => {
	// 			return lesson.status.startsWith(status) && types.includes(thispackage.product.productLessonPack.type.name);
	// 		  })) {
	// 			lessons.push({
	// 			  lesson,
	// 			  productSubscriptionId: thispackage.productLessonPack.productSubscriptionId,
	// 			  customer: thispackage.user,
	// 			  type: thispackage.product.productLessonPack.type.name,
	// 			  creationDate: thispackage.creationDate,
	// 			  expirationDate: getExpirationDate(thispackage),
	// 			  price: thispackage.productLessonPack.price,
	// 			  duration: thispackage.productLessonPack.duration,
	// 			  status: thispackage.productLessonPack.status,
	// 			  closingNotes: thispackage.productLessonPack.closingNotes,
	// 			});
	// 		  }
	// 		});
	// 	  }
	// 	});
	// 	return lessons;
	//   };
	  
	// const getAllLessons = (data: any[], statuses: [string, string[]][]): any[] => {
	// 	var lessons: any[] = [];
	// 	data.forEach((thispackage: any) => {
	// 	  if (thispackage.productLessonPack.lessons && thispackage.productLessonPack.lessons.length > 0) {
	// 		thispackage.productLessonPack.lessons.forEach((lesson: any) => {
	// 		  if (statuses.some(([status, types]: [string, string[]]) => {
	// 			if (types.length === 0) {
	// 			  return lesson.status.startsWith(status);
	// 			} else {
	// 			  return lesson.status.startsWith(status) && types.includes(thispackage.product.productLessonPack.type.name);
	// 			}
	// 		  })) {
	// 			lessons.push({
	// 			  lesson,
	// 			  productSubscriptionId: thispackage.productLessonPack.productSubscriptionId,
	// 			  customer: thispackage.user,
	// 			  type: thispackage.product.productLessonPack.type.name,
	// 			  creationDate: thispackage.creationDate,
	// 			  expirationDate: getExpirationDate(thispackage),
	// 			  price: thispackage.productLessonPack.price,
	// 			  duration: thispackage.productLessonPack.duration,
	// 			  status: thispackage.productLessonPack.status,
	// 			  closingNotes: thispackage.productLessonPack.closingNotes,
	// 			});
	// 		  }
	// 		});
	// 	  }
	// 	});
	// 	return lessons;
	//   };
	  
	// const getAllLessons = (data: any[], statuses: [string, string[]][], packageStatus?: string): any[] => {
	// 	var lessons: any[] = [];
	// 	data.forEach((thispackage: any) => {
	// 	  if ((!packageStatus || thispackage.productLessonPack.status.startsWith(packageStatus)) && thispackage.productLessonPack.lessons && thispackage.productLessonPack.lessons.length > 0) {
	// 		thispackage.productLessonPack.lessons.forEach((lesson: any) => {
	// 		  if (statuses.some(([status, types]: [string, string[]]) => {
	// 			if (types.length === 0) {
	// 			  return lesson.status.startsWith(status);
	// 			} else {
	// 			  return lesson.status.startsWith(status) && types.includes(thispackage.product.productLessonPack.type.name);
	// 			}
	// 		  })) {
	// 			lessons.push({
	// 			  lesson,
	// 			  productSubscriptionId: thispackage.productLessonPack.productSubscriptionId,
	// 			  customer: thispackage.user,
	// 			  type: thispackage.product.productLessonPack.type.name,
	// 			  creationDate: thispackage.creationDate,
	// 			  expirationDate: getExpirationDate(thispackage),
	// 			  price: thispackage.productLessonPack.price,
	// 			  duration: thispackage.productLessonPack.duration,
	// 			  status: thispackage.productLessonPack.status,
	// 			  closingNotes: thispackage.productLessonPack.closingNotes,
	// 			});
	// 		  }
	// 		});
	// 	  }
	// 	});
	// 	return lessons;
	//   };
	  
	// const getAllLessons = (data: any[], statuses: [string, string[], string?][]): any[] => {
	// 	var lessons: any[] = [];
	// 	data.forEach((thispackage: any) => {
	// 	  if (thispackage.productLessonPack.lessons && thispackage.productLessonPack.lessons.length > 0) {
	// 		thispackage.productLessonPack.lessons.forEach((lesson: any) => {
	// 		  if (statuses.some(([status, types, packageStatus]: [string, string[], string?]) => {
	// 			if (packageStatus && !thispackage.productLessonPack.status.startsWith(packageStatus)) {
	// 			  return false;
	// 			}
	// 			if (types.length === 0) {
	// 			  return lesson.status.startsWith(status);
	// 			} else {
	// 			  return lesson.status.startsWith(status) && types.includes(thispackage.product.productLessonPack.type.name);
	// 			}
	// 		  })) {
	// 			lessons.push({
	// 			  lesson,
	// 			  productSubscriptionId: thispackage.productLessonPack.productSubscriptionId,
	// 			  customer: thispackage.user,
	// 			  type: thispackage.product.productLessonPack.type.name,
	// 			  creationDate: thispackage.creationDate,
	// 			  expirationDate: getExpirationDate(thispackage),
	// 			  price: thispackage.productLessonPack.price,
	// 			  duration: thispackage.productLessonPack.duration,
	// 			  status: thispackage.productLessonPack.status,
	// 			  closingNotes: thispackage.productLessonPack.closingNotes,
	// 			});
	// 		  }
	// 		});
	// 	  }
	// 	});
	// 	return lessons;
	//   };

	const getAllLessons = (
		data: any[],
		statuses: [string, string[], string?, boolean?][] // lessonStatus, type, packageStatus, filterByCoachClosingDate
	  ): any[] => {
		var lessons: any[] = [];
		data.forEach((thispackage: any) => {
		  if (thispackage.productLessonPack.lessons && thispackage.productLessonPack.lessons.length > 0) {
			thispackage.productLessonPack.lessons.forEach((lesson: any) => {
			  // Ora il check di filterByCoachClosingDate è fuori dal some()
			  statuses.forEach(([status, types, packageStatus, filterByCoachClosingDate]) => {
				// Check dello stato del pacchetto
				if (packageStatus && !thispackage.productLessonPack.status.startsWith(packageStatus)) {
				  return;
				}
				// Check dello stato della lezione e del tipo
				if ((types.length === 0 && lesson.status.startsWith(status)) ||
					(types.length > 0 && lesson.status.startsWith(status) && types.includes(thispackage.product.productLessonPack.type.name))) {
				  // Controllo aggiuntivo per coachClosingDate
				  if (!(filterByCoachClosingDate && lesson.coachClosingDate == null)) {
					lessons.push({
					  lesson,
					  productSubscriptionId: thispackage.productLessonPack.productSubscriptionId,
					  customer: thispackage.user,
					  type: thispackage.product.productLessonPack.type.name,
					  creationDate: thispackage.creationDate,
					  expirationDate: getExpirationDate(thispackage),
					  price: thispackage.productLessonPack.price,
					  duration: thispackage.productLessonPack.duration,
					  status: thispackage.productLessonPack.status,
					  closingNotes: thispackage.productLessonPack.closingNotes,
					});
				  }
				}
			  });
			});
		  }
		});
		return lessons;
	  };
	  
	const searchNewLessons = async (page: number) => {
		const brand = getBrand();
		if (!brand) return;
		var lessons = [];
		const response = await getSearchAPLessons({ q: searchInput, assigned: false, types: [4], brandCode: brand.code });
		if (response) {
			lessons = response;
		}
		setSearchNewResults(
			lessons.map((lesson: any ) => ({ id: lesson.productLessonPack.productSubscriptionId, customer: lesson.user.id+" | "+lesson.user.name+" "+lesson.user.surname, giftdate: lesson.creationDate }))
		);

		getAllCoaches();

		// setTotalElements(response.totalElements);
		// setTotalPages(response.totalPages);
		// setPageSize(response.size);
	};
	  
	const searchHuntersLessons = async (page: number) => {
		const brand = getBrand();
		if (!brand) return;
		var lessons = [];
		const response = await getSearchHuntersLessons({ q: searchInput, assigned: false, statuses: ['OPENED'], types: [5], brandCode: brand.code });
		if (response) {
			lessons = response;
		}
		setSearchHuntersResults(
			lessons.map((lesson: any ) => ({ id: lesson.productLessonPack.productSubscriptionId, lessonid: lesson.productLessonPack.lessons[0].id, customer: lesson.user.id+" | "+lesson.user.name+" "+lesson.user.surname, nickname: lesson.user.federateds.find((f:any) => f.federatedName === 'Facebook')?.nameOnFederated, phoneNumber: lesson.user.phoneNumber, giftdate: lesson.creationDate }))
		);

		// getAllCoaches();

		// setTotalElements(response.totalElements);
		// setTotalPages(response.totalPages);
		// setPageSize(response.size);

	};

	const searchMyLessons = async () => {
		const brand = getBrand();
		if (!brand) return;
		const response = await getSearchMyLessons({ statuses: ['OPENED'], brandCode: brand.code });
		if (!response) return;
		var subscriptions: any[] = [];
		response.forEach((subscription: any) => {
			if (subscription.product.productLessonPack.type.name === 'Autostrada') {
				let mostRecentClosedDate: string | null = null;
				var closedLessons = 0;
				var openLessons = 0;
				if (subscription.productLessonPack.lessons) {
					subscription.productLessonPack.lessons.forEach((lesson: any) => {
						if (lesson.status !== 'CLOSED') openLessons += 1;
						if (lesson.status === 'CLOSED') {
							closedLessons += 1;
							const lessonClosingDate = lesson.coachClosingDate;
							if (mostRecentClosedDate === null || new Date(lessonClosingDate) > new Date(mostRecentClosedDate)) {
								mostRecentClosedDate = lessonClosingDate;
							}
						}
					});
				}
				subscriptions.push({highwayid: subscription.productLessonPack.productSubscriptionId, customer: subscription.user.id+" | "+subscription.user.name+" "+subscription.user.surname, coach: subscription.productLessonPack.coach, paymentdate: subscription.creationDate, assignmentdate: subscription.productLessonPack.assignmentDate, lessons: subscription.productLessonPack.lessons.length, openlessons: openLessons, lastlesson: mostRecentClosedDate });
			}
		});
		setOpenDetailsResults(subscriptions);
	}

	const searchOpenLessons = async (page: number) => {
		const brand = getBrand();
		if (!brand) return;
		var lessons = [];
		const response = await getSearchMyLessons({ statuses: ['OPENED'], brandCode: brand.code });
		if (response) {
			lessons = getAllLessons(response, [["TO_DO", ["Lezione AP", "Autostrada", "Specialized", "Bonus"]]]); 
		}

		setOriginalOpenResults(lessons);

		setSearchOpenResults(
			lessons.map((lesson: any ) => ({ id: lesson.lesson.id, productsubscriptionid: lesson.productSubscriptionId, customer: lesson.customer.id+" | "+lesson.customer.name+" "+lesson.customer.surname, email: lesson.customer.email, nickname: lesson.customer.nickname, phoneNumber: lesson.customer.phoneNumber, type: lesson.type, duration: lesson.duration, creationDate: lesson.creationDate, expirationDate: lesson.expirationDate, description: lesson.lesson.coach.description, zoom: lesson.lesson.zoom, coachNotes: lesson.lesson.coachNotes }))
		);

	};

	const searchOpenWALessons = async (page: number) => {
		const brand = getBrand();
		if (!brand) return;
		var lessons = [];
		const response = await getSearchMyLessons({ statuses: ['OPENED'], brandCode: brand.code });
		if (response) {
			lessons = getAllLessons(response, [["TO_DO", ["Aiuto Attivo"]], ["CLOSED", ["Aiuto Attivo"]]]); 
		}

		setOriginalOpenResults(lessons);

		setSearchOpenWAResults(
			lessons.map((lesson: any ) => ({ id: lesson.lesson.id, productsubscriptionid: lesson.productSubscriptionId, customer: lesson.customer.id+" | "+lesson.customer.name+" "+lesson.customer.surname, email: lesson.customer.email, nickname: lesson.customer.nickname, phoneNumber: lesson.customer.phoneNumber, type: lesson.type, duration: lesson.duration, creationDate: lesson.creationDate, expirationDate: lesson.expirationDate, description: lesson.lesson.coach.description, zoom: lesson.lesson.zoom, coachNotes: lesson.lesson.coachNotes }))
		);

	};

	const searchClosedLessons = async (page: number) => {
		const brand = getBrand();
		if (!brand) return;
		var lessons = [];
		const response = await getSearchMyLessons({ statuses: ['OPENED', 'CLOSED_SUCCESS', 'CLOSED_FAILURE', 'CLOSED_EXPIRED'], brandCode: brand.code });
		if (response) {
			lessons = getAllLessons(response, [["CLOSED", ["Lezione AP", "Autostrada", "Specialized", "Bonus"], "CLOSED"], ["CLOSED", ["Lezione AP", "Autostrada", "Specialized", "Bonus"], "OPEN", true], ["OPEN", ["Lezione AP", "Autostrada", "Specialized", "Bonus"], "OPEN"]]); 
		}

		setSearchClosedResults(
			lessons.map((lesson: any ) => ({ id: lesson.lesson.id, productsubscriptionid: lesson.productSubscriptionId, customer: lesson.customer.id+" | "+lesson.customer.name+" "+lesson.customer.surname, email: lesson.customer.email, nickname: lesson.customer.nickname, phoneNumber: lesson.customer.phoneNumber, type: lesson.type, duration: lesson.duration, description: lesson.lesson.coach.description, creationDate: lesson.creationDate, expirationDate: lesson.expirationDate, datelesson: lesson.lesson.coachClosingDate, coachprivatenotes: lesson.lesson.coachPrivateNotes, closed: lesson.lesson.status === 'CLOSED', closedSuccess: lesson.status === 'CLOSED_SUCCESS', status: lesson.status, lessonStatus: lesson.lesson.status, closingNotes: lesson.closingNotes, coachNotes: lesson.lesson.coachNotes }))
		);

	};

	const searchClosedWALessons = async (page: number) => {
		const brand = getBrand();
		if (!brand) return;
		var lessons = [];
		const response = await getSearchMyLessons({ statuses: ['OPENED', 'CLOSED_SUCCESS', 'CLOSED_FAILURE', 'CLOSED_EXPIRED'], brandCode: brand.code });
		if (response) {
			lessons = getAllLessons(response, [["OPEN", ["Aiuto Attivo"], "CLOSED"], ["TO_DO", ["Aiuto Attivo"], "CLOSED"], ["CLOSED", ["Aiuto Attivo"], "CLOSED"]]); 
		}

		setSearchClosedWAResults(
			lessons.map((lesson: any ) => ({ id: lesson.lesson.id, productsubscriptionid: lesson.productSubscriptionId, customer: lesson.customer.id+" | "+lesson.customer.name+" "+lesson.customer.surname, email: lesson.customer.email, nickname: lesson.customer.nickname, phoneNumber: lesson.customer.phoneNumber, type: lesson.type, duration: lesson.duration, description: lesson.lesson.coach.description, creationDate: lesson.creationDate, expirationDate: lesson.expirationDate, datelesson: lesson.lesson.coachClosingDate, coachprivatenotes: lesson.lesson.coachPrivateNotes, closed: lesson.lesson.status === 'CLOSED', closedSuccess: lesson.status === 'CLOSED_SUCCESS', status: lesson.status, lessonStatus: lesson.lesson.status, closingNotes: lesson.closingNotes, coachNotes: lesson.lesson.coachNotes }))
		);

	};

	function extractLessonsByCoachIdAndUserId(
		results: any[],
		coachId: number
	  ): any[] {
		const lessons: any[] = [];
	    // console.log("Going to EXTRACT for user "+coachId);
		// console.log("FROM RESULTS: ",results);
		let mostRecentClosedDate: Date | null = null;
		results.forEach((result) => {
			// console.log("Checking subscription "+result.productSubscriptionId);
			if ((result.lesson.coach.userId === coachId) && (result.lesson.type === 'Autostrada')) {
                var closedLessons = 0;
                var openLessons = 0;
				if (result.lessons) {
					result.lessons.forEach((lesson: any) => {
						if (lesson.status !== 'CLOSED') openLessons += 1;
					});
				}
				searchClosedResults.forEach((closedLesson: any) => {
					// console.log(result.productSubscriptionId+" - CLOSED LESSON: ",closedLesson);
					if (closedLesson.status === 'CLOSED') {
						closedLessons += 1; 
						const lessonDate = new Date(closedLesson.lessonDate);
						if (mostRecentClosedDate === null || lessonDate > mostRecentClosedDate) {
							mostRecentClosedDate = lessonDate;
						}
					}
				});
                lessons.push({ highwayid: result.productSubscriptionId, lessons: closedLessons, openLessons: openLessons, user: result.customer, creationDate: result.creationDate, assistant: result.lesson.coach.userId+" | "+result.lesson.coach.name, lastLesson: mostRecentClosedDate });
			}
		  });
		// console.log("EXTRACTED LESSONS: ",lessons);
		return lessons;
	  }

	const searchAssistantDetails = async (assistantId: number) => {
        // // setCoachDetails(assistantId);

		// searchMyLessons();
        // // const detailsTable = extractLessonsByCoachIdAndUserId(originalOpenResults, assistantId);
		// console.log("ORIGINAL: ",detailsSubscriptions);
		// // console.log("DETAILS: ",detailsTable);
		// if (!detailsSubscriptions) return;
		// setOpenDetailsResults(
		// 	detailsSubscriptions.map((response: any) => ({ id: response.id, customer: response.user.id+" | "+response.user.name+" "+response.user.surname, nickname: response.user.federateds.find((f:any) => f.federatedName === 'Facebook')?.nameOnFederated, phoneNumber: response.user.phoneNumber, giftdate: response.creationDate }))
		// );
        // // setOpenDetailsResults(
		// // 	detailsTable.map((lesson: any ) => ({ highwayid: lesson.highwayid, customer: lesson.user.id+" | "+lesson.user.name+" "+lesson.user.surname, assistant: lesson.assistant, paymentdate: lesson.creationDate, lessons: lesson.lessons, openlessons: lesson.openLessons, lastlesson: lesson.lastLesson }))
        // // );
        // // setTotalElements(response.totalElements);
        // setTotalPages(response.totalPages);
        // setPageSize(response.size);
    }

	// useEffect(() => {
	// 	// console.log("OPENDETAILSRESULTS: ",openDetailsResults);
	// }, [openDetailsResults]);

    const handleAssignAssistantSelectChange = (value: string) => {
        setAssignAssistantSelect(Number(value));
    };

    const getCheckedIds = (checkboxes: number[]) => {
        setSelectedIds(checkboxes);
    }

	const assignLessonTo = async () => {
		try {
			selectedIds.forEach(async (id) => {
				await assignLessonToAP(id, assignAssistantSelect);
			});
			const snackbarData: SnackbarOptions = {};
			snackbarData.type = 'success';
			snackbarData.message = `Lezioni assegnate con successo!`;
			dispatch(addSnackbar(snackbarData));
			setTimeout(() => {
				getAllCoaches();
				searchNewLessons(0);
			}, 2500);
		} catch (error: any) {
			const snackbarData: SnackbarOptions = {};
			snackbarData.type = 'error';
			snackbarData.message = `C'è stato qualche problema nell'assegnazione delle lezioni!`;
			dispatch(addSnackbar(snackbarData));
			throw error;
		}
	};

    const assignPackTo = async (lessonPackId: number) => {
		if (!userId) return
		// eslint-disable-next-line no-restricted-globals
		if (confirm("Vuoi veramente prendere in carico la call?")) {
			try {
				await assignPackToAP(lessonPackId, userId);
				const snackbarData: SnackbarOptions = {};
				snackbarData.type = 'success';
				snackbarData.message = `Lezione presa in carico con successo!`;
				dispatch(addSnackbar(snackbarData));
				setTimeout(() => {
					// getAllCoaches();
					searchHuntersLessons(0);
					searchOpenLessons(0);
				}, 100);
			} catch (error: any) {
				const snackbarData: SnackbarOptions = {};
				snackbarData.type = 'error';
				snackbarData.message = `C'è stato qualche problema nel prendere in carico la lezione!`;
				dispatch(addSnackbar(snackbarData));
				throw error;
			}
		}
    };

	const assignBox = (tabSelectorValue === 'tobeassigned') ? (
		<AssignBox>
			<AssignSelect
				disabled={(personalAssistants.length === 0)}
				value={assignAssistantSelect}
				onChange={(event) => handleAssignAssistantSelectChange(event.target.value)}
			>
				{(personalAssistants.length === 0) && <option value={0}>Scegli un assistente</option>}
				{personalAssistants.map((a) => {
					return (
						<option value={a.userId}>
							{a.userId} | {a.name} {a.surname} ({a.lessons ? a.lessons : 0})
						</option>
					);
				})}
			</AssignSelect>

			<AssignButton
				onClick={() => assignLessonTo()}
				disabled={assignAssistantSelect === 0 || selectedIds.length === 0}
			>
				Assegna
			</AssignButton>
		</AssignBox>
	) : (<></>)
	;

	const breadCrumbSettings: TitleBreadCrumbSettings = { subSectionText: 'Gestione Mie Lezioni' };
	var tabItems: TabSelectorItem[] = [
		{	title: 'Whatsapp',
		value: 'hunters',
		},
		// {
		// 	title: 'Salto Coda',
		// 	value: 'tobeassigned',
		// },
	];

	// if (canSeeOpenCalls) tabItems.push({
	// 	title: 'Autostrade',
	// 	value: 'details',
	// });

	if (canSeeOpenCalls && brand?.code === 'LDL') tabItems.push(
		{
			title: 'Aperte',
			value: 'open',
		},
	);
	if (canSeeOpenCalls) tabItems.push(
		{
			title: 'Aperte Whatsapp',
			value: 'openwa',
		},
	);

	if (canSeeClosedCalls && brand?.code === 'LDL') tabItems.push(
		{
			title: 'Chiuse',
			value: 'closed',
		},
	);
	if (canSeeClosedCalls) tabItems.push(
		{
			title: 'Chiuse Whatsapp',
			value: 'closedwa',
		},
	);

	const tabSettings: TabSelectorSettings = { tabItems: tabItems, handleSelection: setTabSelectorValue };

	useEffect(() => {
		const prepareAssistantsList = async () => {
			// if (originalOpenResults.length === 0) 
			// 	{
			// 		await searchOpenLessons(0);
			// 		return;
			// 	}
			if (coaches.length === 0) return;
			// if (searchOpenResults.length === 0) return;
			var assistants: any[] = [];
			if (coaches) {
				assistants = coaches.filter((item: any) => {
					if ((item.user.roles) && (item.user.id === userId)) {
						return item.user.roles.some((role: any) => role.role === 'ASSISTENTE-PERSONALE');
					}
					return false;
				});
			} 
			const assistantsWithLessonCount = assistants.map((assistant) => {
				var assistantCopy = {...assistant}; 
				if (searchOpenResults.length>0) {
					assistantCopy.openroutes = originalOpenResults.reduce((count: number, lesson: any) => {
					if (lesson.lesson && lesson.lesson?.coach?.userId === assistant.userId) {
						return count + 1;
					}
					return count;
					}, 0);
				} else {
					assistantCopy.openroutes = 0;
				}
				return assistantCopy;
			  });
			  
			setPersonalAssistants(assistantsWithLessonCount.sort((a: any, b: any) => {
				return a.user.surname.localeCompare(b.user.surname);
			}));
			// console.log("USERID: ",userId);
			if (userId) searchAssistantDetails(userId);
			if (userId) setAssignAssistantSelect(userId);

		}
		prepareAssistantsList();
		searchMyLessons();
	}, [coaches, userId]);

	// useEffect(() => {
	// 	if (!personalAssistants) return;
	// 	if (!searchHuntersResults) return;

	// }, [personalAssistants, searchHuntersResults]);

	useEffect(() => {
		searchNewLessons(0);
	},[]);

	if (!isSuperAdmin && !isAdmin && !isPersonalTrainer && !isCoach && !isAssistentePersonale && !isSupervisoreAutostrada && !isAdminAutostrada) return (<></>);

	return (
		<>
		<Page>
			<DashboardTitleSection breadCrumbSettings={breadCrumbSettings}>
				{false && <SearchChannelBox>
					<SearchInput
						onKeyDown={searchKeyHandler}
						onChange={searchChangeHandler}
						value={searchInput}
					/>

					{searchInput !== '' && (
						<SearchClearButton onClick={clearSearchHandler}>
							<ClearButtonIconSVG />
						</SearchClearButton>
					)}

					<SearchButton>
						<SearchIconSVG />
					</SearchButton>
				</SearchChannelBox>
				}
			</DashboardTitleSection>

			<TabSelector tabSelectorSettings={tabSettings}></TabSelector>

			<BaseContentCard>
				{tabSelectorValue === 'tobeassigned' && (
					<>
					{assignBox}
						<APLessonsNewTable
							data={searchNewResults}
							totalElements={totalElements}
							totalPages={totalPages}
							pageSize={pageSize}
							searchLessons={searchNewLessons}
							getCheckedIds={getCheckedIds}
						/>
					{assignBox}
					</>
				)}
				{tabSelectorValue === 'hunters' && (
					<>
						<MyLessonsHuntersTable
							data={searchHuntersResults}
							totalElements={totalElements}
							totalPages={totalPages}
							pageSize={pageSize}
							searchLessons={searchHuntersLessons}
							assignLesson={canTakeChargeHunters ? assignPackTo : undefined}
						/>
					</>
				)}
				{tabSelectorValue === 'open' && (
					<>
						<MyLessonsOpenTable
							data={searchOpenResults}
							totalElements={totalElements}
							totalPages={totalPages}
							pageSize={pageSize}
							personalAssistants={personalAssistants}
							searchLesson={searchOpenLessons}
						/>
					</>
				)}
				{tabSelectorValue === 'openwa' && (
					<>
						<MyLessonsOpenTable
							data={searchOpenWAResults}
							totalElements={totalElements}
							totalPages={totalPages}
							pageSize={pageSize}
							personalAssistants={personalAssistants}
							searchLesson={searchOpenWALessons}
						/>
					</>
				)}
				{tabSelectorValue === 'closed' && (
					<>
						<MyLessonsClosedTable
							data={searchClosedResults}
							totalElements={totalElements}
							totalPages={totalPages}
							pageSize={pageSize}
							searchLesson={searchClosedLessons}
						/>
					</>
				)}
				{tabSelectorValue === 'closedwa' && (
					<>
						<MyLessonsClosedTable
							data={searchClosedWAResults}
							totalElements={totalElements}
							totalPages={totalPages}
							pageSize={pageSize}
							searchLesson={searchClosedWALessons}
						/>
					</>
				)}
			</BaseContentCard>
		</Page>
		</>
	);
};

export default MyLessonsPage;
